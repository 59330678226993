import FormFieldController from "decor/forms/form_field_controller";

export default class CheckboxController extends FormFieldController {
  public set checked(state: boolean) {
    this.inputTarget!.checked = state;
  }

  public get checked() {
    return this.inputTarget!.checked;
  }
}
