import { AxiosResponse } from "axios";
import { parse } from "query-string";
import UnreadItemController from "controllers/layouts/message_threads/unread_item_controller";
import axios from "lib/axios";
import BaseController from "decor/base_controller";
import parseDateTime from "lib/util/parse_datetime_attr";
import { MessageNotificationEvent } from "lib/types";
import { Layouts__MessageThreads__UnreadItemControllerIdentifier } from "controllers/identifiers";

export default class UnreadMessagesController extends BaseController {
  public static targets = ["items"];

  private declare readonly itemsTarget: HTMLDivElement;

  public static outlets = [
    Layouts__MessageThreads__UnreadItemControllerIdentifier,
  ];
  private declare readonly layoutsMessageThreadsUnreadItemOutlets: UnreadItemController[];
  private get messageControllers() {
    return this.layoutsMessageThreadsUnreadItemOutlets;
  }

  public onInitialize() {
    this.onConnect(() => {
      if ("InstallTrigger" in window) {
        this.itemsTarget.style.minHeight = "0";
      }
    });

    return super.onInitialize();
  }

  public load(e: CustomEvent): void {
    const {
      detail: { message },
    } = e as MessageNotificationEvent;
    if (
      message.message_thread_encoded_id !== this.data.get("thread-encoded_id")
    ) {
      this.fetchUnread(message.message_thread_encoded_id);
    }
  }

  private fetchUnread(messageThreadUid: string): void {
    axios
      .get(this.unreadUrl(messageThreadUid))
      .then((response: AxiosResponse) => response.data)
      .then((html) => this.addNotification(html, messageThreadUid))
      .catch((e: Error) => {
        alert(e.message);
      });
  }

  private addNotification(html: string, messageThreadUid: string) {
    const threadNotification = this.messageControllers.find(
      (controller: UnreadItemController) =>
        controller.threadUid === messageThreadUid,
    );

    if (threadNotification) {
      // Notification item exsists, remove old content
      this.removeItem(threadNotification);
    }

    this.prependNotificationNode(html);
  }

  private prependNotificationNode(html: string) {
    const sentAt = parseDateTime(html);
    const afterNode = this.firstItemSentBefore(sentAt);

    afterNode
      ? afterNode.scope.element.insertAdjacentHTML("beforebegin", html)
      : this.itemsTarget.insertAdjacentHTML("afterbegin", html);
  }

  private unreadUrl(messageThreadUid: string): string {
    let url = `/account/messages/threads/${messageThreadUid}/messages/unread`;
    const parts = parse(window.location.search);

    if (parts.shared_inbox_encoded_id) {
      url += `?shared_inbox_encoded_id=${parts.shared_inbox_encoded_id}`;
    }

    return url;
  }

  private firstItemSentBefore(
    sentAt: number,
  ): UnreadItemController | undefined {
    return this.messageControllers.find(
      (controller: UnreadItemController) => controller.sentAt < sentAt,
    );
  }

  private removeItem(controller: UnreadItemController) {
    const index = this.messageControllers.indexOf(controller);
    controller.scope.element.remove();
    this.messageControllers.splice(index, 1);
  }
}
