import BaseController from "decor/base_controller";
import { showNotification } from "lib/overlay/notification";
import { markAsSafeHTML } from "lib/util/safe_html";

export default class ClickToCopyController extends BaseController {
  public static readonly targets = ["content"];

  public declare readonly contentTarget: HTMLDivElement;

  public copy() {
    this.selectText();
    showNotification({
      content: markAsSafeHTML("<p class='p-4'>Copied!</p>"),
      timeout: 1000,
    });
  }

  private selectText() {
    const range = document.createRange();
    range.selectNodeContents(this.contentTarget);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);

      document.execCommand("copy");
      selection.removeAllRanges();
    }
  }
}
