import { Decor__Forms__CheckboxControllerIdentifier } from "controllers/identifiers";
import BaseController from "decor/base_controller";
import CheckboxController from "decor/forms/checkbox_controller";

export default class DataTableHeaderRowController extends BaseController {
  public static outlets = [Decor__Forms__CheckboxControllerIdentifier];
  private declare readonly decorFormsCheckboxOutlet: CheckboxController;
  private get checkboxController() {
    return this.decorFormsCheckboxOutlet;
  }

  private checkboxChangeListeners: Set<(value: boolean) => void> = new Set();

  public onInitialize() {
    this.onDisconnect(() => {
      this.checkboxChangeListeners.clear();
    });

    return super.onInitialize();
  }

  public get checked(): boolean {
    return !!this.checkboxController?.checked;
  }

  public set checked(state: boolean) {
    if (this.checkboxController) {
      this.checkboxController.checked = state;
    }
  }

  public handleCheckboxChange(evt: Event) {
    const target = evt.target as HTMLInputElement;
    this.checkboxChangeListeners.forEach((listener) => {
      listener(target.checked);
    });
  }

  public onCheckboxChange(cb: (checked: boolean) => void) {
    this.checkboxChangeListeners.add(cb);
    return () => this.checkboxChangeListeners.delete(cb);
  }
}
