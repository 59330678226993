import BaseController from "decor/base_controller";

export default class TabsController extends BaseController {
  public handleSelectTabOnMobile(event: Event) {
    const select = event.target as HTMLSelectElement;
    const selected = select.options[select.selectedIndex];

    const href = selected.getAttribute("data-href");
    if (href) {
      window.location.href = href;
    }
  }
}
