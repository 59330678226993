import {
  getAppConfiguration,
  setAppConfiguration,
} from "lib/app_configuration";
import BaseController from "decor/base_controller";
import { addScriptTag } from "lib/util/add_script_tag";
import { MapCompanyLocation, MapOverlay, MapPoint } from "lib/types";

// Hacky quick solution to show a map - need to turn this info a proper component
// and there maybe issues with inline styles and CSP...
// FIXME: is a XSS waiting to happen with user specified name for locations etc?
export default class MapController extends BaseController {
  public static targets = ["mapContainer"];

  private declare readonly mapContainerTarget: HTMLDivElement;

  private googleMap!: google.maps.Map | null;

  // Note: we should also consider adjusting zoom to fit all placed geometry
  // https://developers.google.com/maps/documentation/javascript/examples/layer-data-dragndrop

  public onInitialize() {
    this.attachMapScript();

    this.onConnect(() => {
      this.onMapReady(() => {
        this.createMap();
        this.addOverlays();
        this.addPoints();
      });
    });

    this.onDisconnect(() => {
      this.googleMap = null;
    });

    return super.onInitialize();
  }

  private attachMapScript() {
    if (document.getElementById("google-maps-script-tag")) {
      return;
    }
    window.__mapInitialised = this.mapInitialised.bind(this);
    const key = this.data.get("api-key");
    const src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=__mapInitialised`;
    addScriptTag(src, {
      async: true,
      defer: true,
      id: "google-maps-script-tag",
    });
  }

  private mapInitialised() {
    setAppConfiguration("mapHasLoaded", true);
    const evt = new CustomEvent("google-maps-script:ready", {
      bubbles: true,
      cancelable: false,
    });
    document.dispatchEvent(evt);
  }

  private onMapReady(callback: () => void) {
    if (getAppConfiguration("mapHasLoaded")) {
      return callback();
    }
    const readyListener = () => {
      callback();
      document.removeEventListener("google-maps-script:ready", readyListener);
    };
    document.addEventListener("google-maps-script:ready", readyListener);
  }

  private createMap() {
    this.googleMap = new google.maps.Map(this.mapContainerTarget, {
      center: this.mapCenter,
      zoom: parseInt(this.data.get("zoom") || "10", 10),
    });
  }

  private addPoints() {
    const pointsData = this.data.get("points");
    if (!pointsData) {
      return;
    }
    try {
      const points = JSON.parse(pointsData) as MapCompanyLocation[];
      points.forEach((location) => {
        const marker = new google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          title: `${location.description} (${location.name})`,
        });
        marker.setMap(this.googleMap);
      });
    } catch (e: any) {
      console.error("Could not render the map points", e.message);
    }
  }

  // https://coderwall.com/p/49ygrg/geojson-multipolygon-to-google-maps-polygon
  private addOverlays() {
    const regionsData = this.data.get("overlays");
    if (!regionsData) {
      return;
    }
    try {
      const regions = JSON.parse(regionsData) as MapOverlay[];
      regions.forEach((region) => {
        const paths = region.coordinates.flatMap((polygon) => {
          return polygon.flatMap((path) => {
            return path.map((point) => {
              // Important: the lat/lng are vice-versa in GeoJSON
              return new google.maps.LatLng(point[1], point[0]);
            });
          });
        });
        const mapPolygon = new google.maps.Polygon({ paths });
        mapPolygon.setMap(this.googleMap);
      });
    } catch (e: any) {
      console.error("Could not render the map overlays", e.message);
    }
  }

  private get mapCenter() {
    const centerData = this.data.get("center");
    if (!centerData) {
      return;
    }
    try {
      const center = JSON.parse(centerData) as MapPoint;
      return { lat: center.lat, lng: center.lng };
    } catch (error) {
      return { lat: 33.6265064, lng: -84.5319427 };
    }
  }
}
