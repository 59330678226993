import { SIDE_NAVBAR_MOBILE_TOGGLE_EVENT } from "navigo/side_navbar_controller";
import NavbarWithSearchController from "navigo/navbar_with_search_controller";

export default class TopNavbarController extends NavbarWithSearchController {
  public static targets = ["search"].concat(NavbarWithSearchController.targets);

  private declare readonly searchTarget: HTMLDivElement;

  public toggleMobileMenu() {
    const evt = new CustomEvent(SIDE_NAVBAR_MOBILE_TOGGLE_EVENT, {
      bubbles: true,
      cancelable: false,
    });
    window.dispatchEvent(evt);
  }

  public gotSearchFocus() {
    this.setTargetElementClasses(
      this.searchTarget,
      ["md:w-1/4"],
      ["transform", "ease-in", "duration-200"],
    );
    if (this.searchText()) {
      if (!this.hasSearchResults) {
        this.search();
      } else {
        this.dropdownShow(true);
      }
    }
  }

  public lostSearchFocus() {
    this.setTargetElementClasses(
      this.searchTarget,
      [],
      ["md:w-1/4", "transform", "ease-out", "duration-200"],
    );
  }
}
