import flatpickr from "flatpickr";
import { Instance } from "flatpickr/dist/types/instance";
import {
  buildFlatpickrOptions,
  DataAttributes,
} from "lib/util/build_flatpickr_options";
import FormFieldController from "decor/forms/form_field_controller";

export default class DateCalendarController extends FormFieldController {
  public static targets = ["datepicker"];

  private declare readonly datepickerTarget: HTMLInputElement;

  private datePicker!: Instance | null;
  private ticking = false;

  public onInitialize() {
    this.onConnect(() => {
      const dataAttributes =
        this.getRequiredDataAttrAsJSON<DataAttributes>("data");

      // Build flatpickr instance
      const flatpickrOpts = buildFlatpickrOptions(dataAttributes);
      this.datePicker = flatpickr(this.datepickerTarget, flatpickrOpts);

      return () => {
        this.datePicker!.destroy();
        this.datePicker = null;
      };
    });

    return super.onInitialize();
  }

  public scrolled() {
    if (!this.datePicker || !this.datePicker.isOpen) {
      return;
    }
    // The ticking check ensures that this.updateCalendarPosition() is called at most once per animation
    // frame, no matter how many times the scroll event fires.
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.updateCalendarPosition();
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  public isOpen() {
    return this.datePicker ? this.datePicker.isOpen : false;
  }

  public open() {
    if (this.datePicker) {
      this.datePicker!.open();
    }
  }

  public close() {
    if (this.datePicker) {
      this.datePicker!.destroy();
    }
  }

  // This updates position of calendar, needed when the calendar is on a modal to ensure it scrolls with the modal.
  private updateCalendarPosition() {
    if (this.datePicker) {
      this.datePicker._positionCalendar();
    }
  }
}
