import { SafeHTMLContent } from "lib/types";

/**
 * Explicitly marks HTML content as 'safe'.
 * This attempts to mitigate the possibility of XSS by creating an extra hoop for the developer to jump through when using `innerHTML`.
 *
 * @param html HTML content to mark as safe
 */
export function markAsSafeHTML(html: string): SafeHTMLContent {
  return {
    __safe: true,
    content: html,
  };
}

/**
 * Proxies to the element's `innerHTML` method, ensuring that the content that is being set has previously been marked as 'safe'.
 *
 * @param el element to set innerHTML
 * @param safe safe content to set as innerHTML
 */
export function safelySetInnerHTML(
  el: Element,
  { __safe, content }: SafeHTMLContent,
) {
  // It's temping to modify the Element's prototype here with a new `safelySetInnerHTML` method, but...
  // http://perfectionkills.com/whats-wrong-with-extending-the-dom/
  if (!__safe) {
    throw new Error(
      "This content could not be displayed as it has not been marked as safe. Content must be explicitly marked as safe to try and reduce the likelihood of a XSS attack.",
    );
  }
  el.innerHTML = content; // tslint:disable-line no-inner-html
}
