import BaseController from "decor/base_controller";
import ModifyOrderFormController from "mercor/order_management/modify_order_form_controller";

export default class BreakdownRemoveItemController extends BaseController {
  public static targets = ["input"];

  private declare readonly inputTarget: HTMLInputElement;

  public handleRemoveIconClick() {
    this.toggleInput();
    this.dispatchRemoveEvent();
  }

  private get rowId() {
    return this.getRequiredDataAttr("rowId");
  }

  private dispatchRemoveEvent() {
    this.dispatch("disable-toggled", {
      prefix: ModifyOrderFormController.identifier,
      bubbles: true,
      cancelable: false,
      detail: {
        rowId: this.rowId,
      },
    });
  }

  private toggleInput() {
    if (this.inputTarget.value === "true") {
      this.inputTarget.value = "false";
    } else {
      this.inputTarget.value = "true";
    }
  }
}
