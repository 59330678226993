import { SafeHTMLContent } from "lib/types";
import FormFieldController from "decor/forms/form_field_controller";
import { markAsSafeHTML, safelySetInnerHTML } from "lib/util/safe_html";

export default class SelectController extends FormFieldController {
  public onInitialize() {
    this.onConnect(() => {
      this.inputTarget.addEventListener("change", this.handleChangeEvent);
      this.styleOption();
      return () => {
        this.inputTarget.removeEventListener("change", this.handleChangeEvent);
      };
    });

    return super.onInitialize();
  }

  public styleOption() {
    let blankSelected = false;
    for (const option of this.inputTarget.children) {
      const opt = option as HTMLOptionElement;
      const isBlank = this.hasBlankOrPlaceholder && opt.value === "";
      if (isBlank && this.value == opt.value) {
        blankSelected = true;
      }
    }
    this.toggleTargetElementClasses(
      this.inputTarget,
      blankSelected,
      [],
      ["text-disabled"],
    );
  }

  private get hasBlankOrPlaceholder() {
    return this.getOptionalDataAttr("hasBlankOrPlaceholder");
  }

  public get optionsHTML() {
    return this.inputTarget.innerHTML;
  }

  public set options(options: SafeHTMLContent) {
    safelySetInnerHTML(this.inputTarget, options);
  }

  public empty(message: string) {
    this.options = markAsSafeHTML(
      `<option value="" selected>${message}</option>`,
    );
  }

  private handleChangeEvent = (evt: Event) => {
    const name = this.data.get("name");
    const target = evt.target as HTMLSelectElement;
    this.styleOption();
    this.emitEvent(this.element, SelectController, "change", {
      name,
      index: target.selectedIndex,
      value: target.value,
    });
  };
}
