import ProductCategoryFilterTierController from "mercor/product_category_filter_tier_controller";
import BaseController from "decor/base_controller";
import {
  addParametersToQueryString,
  removeParameterFromQueryString,
} from "lib/util/add_parameter_to_url";
import { Category } from "lib/types";
import { Mercor__ProductCategoryFilterTierControllerIdentifier } from "controllers/identifiers";

export default class ProductCategoryFiltersController extends BaseController {
  public static outlets = [
    Mercor__ProductCategoryFilterTierControllerIdentifier,
  ];
  private declare readonly mercorProductCategoryFilterTierOutlets: ProductCategoryFilterTierController[];
  private get tierControllers() {
    return this.mercorProductCategoryFilterTierOutlets;
  }

  public items!: Category[];

  private debounceTimer: any = undefined;
  private timerStartTime: number = 0;

  public onInitialize() {
    this.items = this.parseItems();
    return super.onInitialize();
  }

  public tier0Selected({ detail: { index } }: CustomEvent) {
    this.tierSelected(index, 0);
  }

  public tierClicked() {
    this.tierExpanded();
  }

  public tier1Selected({ detail: { index } }: CustomEvent) {
    this.tierSelected(index, 1);
  }

  public tier2Selected({ detail: { index } }: CustomEvent) {
    this.tierSelected(index, 2);
  }

  public tier3Selected({ detail: { index } }: CustomEvent) {
    this.tierSelected(index, 3);
  }

  public submit(e?: Event) {
    if (e) e.preventDefault();
    const lastSelectedUid =
      this.selectedValues[this.selectedValues.length - 1] ||
      this.data.get("rootSluggedId")!;

    if (lastSelectedUid !== this.currentUid) {
      const newPathname = window.location.pathname.replace(
        this.currentUid,
        lastSelectedUid,
      );
      // Remove the paging parameters
      window.location.href =
        newPathname +
        "?" +
        removeParameterFromQueryString(window.location.search, "page");
    }
  }

  public embeddedSubmit(e: Event) {
    e.preventDefault();

    const lastSelectedUid =
      this.selectedValues[this.selectedValues.length - 1] ||
      this.data.get("rootSluggedId")!;

    if (lastSelectedUid !== this.currentUid) {
      const newPathname = "/marketplace/categories/" + lastSelectedUid;
      window.location.pathname = newPathname;
    }
  }

  public reorder({ detail: { value } }: CustomEvent) {
    if (value) {
      const params = { order_by: value };
      window.location.search = addParametersToQueryString(
        window.location.search,
        params,
      );
    } else {
      window.location.search = removeParameterFromQueryString(
        window.location.search,
        "order_by",
      );
    }
  }

  private tierSelected(index: number, tier: number) {
    const newSelectedCategory = this.tierControllers[tier].items[index - 1];
    const nextTierControllers = this.tierControllers.slice(tier + 1);

    for (const tierController of nextTierControllers) {
      tierController.disabled = true;
    }

    if (index > 0 && newSelectedCategory) {
      if (tier < this.tierControllers.length && newSelectedCategory.c?.length) {
        // Set options for next input
        const nextTierController = this.tierControllers[tier + 1];
        nextTierController.options = newSelectedCategory.c;
        nextTierController.disabled = false;
      }

      if (this.getOptionalDataAttr("autoSubmit") == "true") {
        if (this.debounceTimer) {
          this.clearDebounceTimer();
        }
        this.timerStartTime = Date.now();
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = undefined;
          this.submit();
        }, 2000);
      }
    }
  }

  private clearDebounceTimer() {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = undefined;
    this.timerStartTime = 0;
  }

  private tierExpanded() {
    if (this.getOptionalDataAttr("autoSubmit") == "true") {
      // If the timer is running and has been more than some milliseconds,
      // then clear the timer, as we are expecting the user has expanded a tier.
      // We can't clear the timer no matter what, as the user may have expanded
      // and then selected at the same time (eg via automation) and the order
      // in which the events are fired is not guaranteed to ensure the timer
      // is not cleared as soon as it is set (set by the change event).
      if (this.shouldClearDebounceTimer()) {
        this.clearDebounceTimer();
      }
    }
  }

  private shouldClearDebounceTimer() {
    return this.debounceTimer && Date.now() - this.timerStartTime > 100;
  }

  private parseItems(): Category[] {
    const itemsStr = this.data.get("categories");

    if (itemsStr !== null) {
      try {
        return JSON.parse(itemsStr) as Category[];
      } catch (e: any) {
        throw new Error(
          `Could not parse category filter items: for controller ${this.identifier}, was not valid JSON: ${itemsStr}`,
        );
      }
    }

    return [] as Category[];
  }

  private get currentUid(): string {
    const categoryAndQuery = window.location.pathname.split("/").pop() || "";
    return categoryAndQuery.split("?").pop() || "";
  }

  private get selectedValues(): string[] {
    return this.tierControllers.map((tc) => tc.value).filter((v) => !!v);
  }
}
