import {
  Decor__Tables__DataTableHeaderRowControllerIdentifier,
  Decor__Tables__DataTableRowControllerIdentifier,
} from "controllers/identifiers";
import BaseController from "decor/base_controller";
import DataTableHeaderRowController from "decor/tables/data_table_header_row_controller";
import DataTableRowController from "decor/tables/data_table_row_controller";

export default class DataTableController extends BaseController {
  public static outlets = [
    Decor__Tables__DataTableHeaderRowControllerIdentifier,
    Decor__Tables__DataTableRowControllerIdentifier,
  ];
  private declare readonly decorTablesDataTableHeaderRowOutlet: DataTableHeaderRowController;
  private declare readonly hasDecorTablesDataTableHeaderRowOutlet: boolean;
  private get headerRowController() {
    return this.decorTablesDataTableHeaderRowOutlet;
  }
  private get hasHeaderRowController() {
    return this.hasDecorTablesDataTableHeaderRowOutlet;
  }
  private declare readonly decorTablesDataTableRowOutlets: DataTableRowController[];
  private get rowControllers() {
    return this.decorTablesDataTableRowOutlets;
  }

  public static targets = ["tableContentContainer", "tableBody"];

  private declare readonly tableContentContainerTarget: HTMLDivElement;
  private declare readonly tableBodyTarget: HTMLTableSectionElement;

  public onInitialize() {
    this.onConnect(() => {
      let removeListener = () => {};

      if (this.hasHeaderRowController) {
        // When a checkbox changes, toggle all rows.
        this.headerRowController.onCheckboxChange(this.toggleRows.bind(this));
      }

      // Remove the listener when the controller disconnects.
      return removeListener;
    });

    this.contentScrolled();

    return super.onInitialize();
  }

  public toggleRows(checked: boolean) {
    this.rowControllers.forEach((controller) => {
      controller.checked = checked;
    });
  }

  public appendRow(row: HTMLElement) {
    this.tableBodyTarget.appendChild(row);
    this.contentScrolled();
  }

  public contentScrolled() {
    const target = this.tableContentContainerTarget;
    const x = target.scrollLeft;
    const divWidth = target.scrollWidth - target.clientWidth;
    if (x == 0) {
      target.classList.remove("inset-scroll-shadow-not-at-left");
    }

    if (x > 0) {
      target.classList.add("inset-scroll-shadow-not-at-left");
    }

    if (x < divWidth) {
      target.classList.add("inset-scroll-shadow-not-at-right");
    }

    if (x == divWidth) {
      target.classList.remove("inset-scroll-shadow-not-at-right");
    }
  }
}
