import BaseController from "decor/base_controller";
import OrderGuideRowController, {
  ProductPriceChangedEvent,
} from "./order_guide_row_controller";
import { BIG_ZERO, newPositiveBigOrNull } from "lib/price_calculations";
import Big from "big.js";
import { Mercor__OrderGuideRowControllerIdentifier } from "controllers/identifiers";

export default class OrderGuideController extends BaseController {
  public static targets = ["searchField", "totalPrice", "totalPriceFooter"];
  private rows: { [key: string]: Big } = {};
  private declare readonly searchFieldTarget: HTMLFormElement;
  private declare readonly totalPriceTarget: HTMLFormElement;
  private declare readonly totalPriceFooterTarget: HTMLFormElement;

  public static outlets = [Mercor__OrderGuideRowControllerIdentifier];
  private declare readonly mercorOrderGuideRowOutlets: OrderGuideRowController[];
  private get rowControllers() {
    return this.mercorOrderGuideRowOutlets;
  }

  private hasChanges = false;
  private formSubmitting = false;
  private sortDirection: { [key: string]: boolean } = {};

  private debounceSearch?: any = undefined;

  onInitialize() {
    this.rows = {};

    window.addEventListener("beforeunload", (e) => {
      if (this.formSubmitting || !this.hasChanges) {
        return undefined;
      }
      const confirmationMessage =
        "It looks like you have been editing your order guide. " +
        "If you leave this page before Adding to Cart, your changes will be lost.";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });

    return super.onInitialize();
  }

  public submittedForm(evt: Event) {
    this.formSubmitting = true;
  }

  public sortBySku() {
    this.applySort("sku");
  }

  public sortByDescription() {
    this.applySort("description");
  }

  public search(evt: Event) {
    const val = this.searchFieldTarget.value.trim().toLowerCase();

    if (this.debounceSearch) {
      clearTimeout(this.debounceSearch);
    }
    this.debounceSearch = setTimeout(() => {
      this.debounceSearch = undefined;
      this.rowControllers.forEach((rowController: OrderGuideRowController) => {
        rowController.hideOnSearch(val);
      });
    }, 300);
  }

  // Each product row sends a product_price_changed event, and these are used to infer how many rows there are
  // and calculate the order guide Estimated Total.
  public handleRowPriceChange(evt: ProductPriceChangedEvent) {
    const { totalPriceNumber, sku } = evt.detail;
    const price = newPositiveBigOrNull(totalPriceNumber) || BIG_ZERO;
    if (price.gt(0)) {
      this.hasChanges = true;
    }
    this.rows[sku] = price;
    const estimate = Object.values(this.rows).reduce<Big>(
      (a: Big, b: Big) => a.plus(b),
      BIG_ZERO,
    );
    if (estimate.eq(0)) {
      this.hasChanges = false;
    }
    this.updateTotalPrice(estimate);
  }

  private applySort(parameter: string) {
    if (!this.sortDirection.hasOwnProperty(parameter)) {
      this.sortDirection[parameter] = false;
    }
    const elements = this.rowControllers.map(
      (rowController: OrderGuideRowController) => {
        const parent = rowController.element.parentElement;
        if (parent) parent.removeChild(rowController.element);
        return [
          rowController.sortableParam(parameter),
          rowController.element,
          parent,
        ];
      },
    );
    const sorted = elements.sort((a, b) => {
      if (this.sortDirection[parameter]) {
        return (a[0] as string).localeCompare(b[0] as string);
      } else {
        return (b[0] as string).localeCompare(a[0] as string);
      }
    });
    sorted.forEach(([sku, element, parent]) => {
      if (parent) (parent as HTMLElement).appendChild(element as HTMLElement);
    });
    this.sortDirection[parameter] = !this.sortDirection[parameter];
  }

  private updateTotalPrice(price: any) {
    // TODO: use local formatting, highest precision, handle different currencies etc.
    this.totalPriceFooterTarget.textContent =
      this.totalPriceTarget.textContent = `$ ${price.toFixed(2)}`;
  }
}
