import NavbarWithSearchController from "navigo/navbar_with_search_controller";

export default class NavbarController extends NavbarWithSearchController {
  public static targets = [
    "mobileMenu",
    "mobileMenuIconOpen",
    "mobileMenuIconClose",
    "search",
  ].concat(NavbarWithSearchController.targets);

  private declare readonly mobileMenuTarget: HTMLElement;
  private declare readonly mobileMenuIconOpenTarget: HTMLElement;
  private declare readonly mobileMenuIconCloseTarget: HTMLElement;
  private declare readonly searchTarget: HTMLDivElement;

  public toggleMobileMenu(): void {
    this.mobileMenuTarget.classList.toggle("hidden");
    this.mobileMenuIconOpenTarget.classList.toggle("hidden");
    this.mobileMenuIconCloseTarget.classList.toggle("hidden");
  }

  public gotSearchFocus() {
    this.setTargetElementClasses(
      this.searchTarget,
      ["md:w-1/4"],
      ["transform", "ease-in", "duration-200"],
    );
    if (this.searchText()) {
      if (!this.hasSearchResults) {
        this.search();
      } else {
        this.dropdownShow(true);
      }
    }
  }

  public lostSearchFocus() {
    this.setTargetElementClasses(
      this.searchTarget,
      [],
      ["md:w-1/4", "transform", "ease-out", "duration-200"],
    );
  }
}
