// External SVG loader
import "external-svg-loader";

// Third-party libraries
import flatpickr from "flatpickr";

// Choices has types but get an error with strict checks enabled...
// @ts-ignore
import Choices from "choices.js";

document
  .querySelectorAll("[data-select-searchable]")
  .forEach(
    (el) => new Choices(el as HTMLSelectElement, { searchResultLimit: 8 }),
  );

document.querySelectorAll("[data-select-multiple-tags]").forEach(
  (el) =>
    new Choices(el as HTMLSelectElement, {
      removeItemButton: true,
      searchResultLimit: 8,
      allowHTML: true,
    }),
);

document.querySelectorAll("[data-input-multiple-tags]").forEach(
  (el) =>
    new Choices(el as HTMLInputElement, {
      delimiter: ",",
      editItems: true,
      removeItemButton: true,
    }),
);

import Chartkick from "chartkick";
import {
  Chart,
  LineElement,
  LineController,
  BarElement,
  BarController,
  PieController,
  DoughnutController,
  LinearScale,
  TimeScale,
  CategoryScale,
  PointElement,
  Legend,
  Title,
  Tooltip,
  ArcElement,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";

import gradient from "chartjs-plugin-gradient";
Chart.register(gradient);

Chart.register(
  Filler,
  ArcElement,
  LineElement,
  LineController,
  BarElement,
  BarController,
  PieController,
  DoughnutController,
  LinearScale,
  TimeScale,
  CategoryScale,
  PointElement,
  Legend,
  Title,
  Tooltip,
);
Chartkick.use(Chart);

(window as any).Chartkick = Chartkick;

// Init charts
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.registry.plugins.unregister(ChartDataLabels);

const urlParams = new URLSearchParams(window.location.search);

if (urlParams.get("datalabels")) {
  Chart.register(ChartDataLabels);
  Chart.defaults.set("plugins.datalabels", {
    backgroundColor: function (context: any) {
      return context.dataset.backgroundColor;
    },
    borderColor: "white",
    borderWidth: 2,
    borderRadius: 4,
    color: "white",
    font: { weight: "bold" },
  });
}
