// Inspired heavily by https://github.com/cferdinandi/bouncer
export interface ValueRequiredAttributes {
  required: boolean;
  value: string;
}

export interface CheckableValueRequiredAttributes {
  required: boolean;
  checked: boolean;
}

export interface PatternMismatchAttributes {
  pattern: string | null;
  type: string;
  value: string;
}

export interface OutOfRangeAttributes {
  min: string | null;
  max: string | null;
  lt: string | null;
  gt: string | null;
  value: string;
}

export interface WrongLengthAttributes {
  min: string | null;
  max: string | null;
  value: string;
}

export interface ITypeMismatchAttributes {
  type: string | null;
  value: string;
}

interface PatternType {
  [key: string]: RegExp;
}
type RangeResponseType = false | "over" | "under" | "invalid";

const PATTERNS: PatternType = {
  color: /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/,
  date: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/,
  email:
    /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$/,
  month: /^(?:(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])))$/,
  number: /^(?:[-+]?[0-9]*[.,]?[0-9]+)$/,
  time: /^(?:(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]))$/,
  url: /^(?:(?:https?|HTTPS?|ftp|FTP):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
};

export function missingValue(attrs: ValueRequiredAttributes): boolean {
  return attrs.required && !attrs.value.length;
}

export function missingCheckboxValue(
  attrs: CheckableValueRequiredAttributes,
): boolean {
  return attrs.required && !attrs.checked;
}

export function missingRadioValue(
  attrs: CheckableValueRequiredAttributes[],
): boolean {
  // Any is required && none are checked
  return (
    attrs.some((attr) => attr.required) && attrs.every((attr) => !attr.checked)
  );
}

export function patternMismatch(attrs: PatternMismatchAttributes): boolean {
  const { pattern, type, value } = attrs;

  // Make sure field has value
  if (!value) {
    return false;
  }

  // TODO since we are dynamically creating a regex, there's a possibility this could cause a Regex DOS.
  // We could alleviate this through a package such as https://github.com/davisjam/safe-regex
  // Not including at present as currently we are in charge of the field pattern passed in
  // (it will most likely be the value from `pattern="<value>"` on a HTML element)
  const chosenPattern = pattern
    ? new RegExp("^(?:" + pattern + ")$")
    : PATTERNS[type];
  if (!chosenPattern) {
    return false;
  }

  return !value.match(chosenPattern);
}
export function outOfRange(attrs: OutOfRangeAttributes): RangeResponseType {
  const { max, min, gt, lt, value } = attrs;

  // Make sure field has value
  if (!value) {
    return false;
  }

  // Should be a number
  if (
    (gt !== null || lt !== null || min !== null || max !== null) &&
    !value.match(PATTERNS["number"])
  ) {
    return "invalid";
  }

  // Check validity
  const num = parseFloat(value);

  // Check that the value is not less than or equal to the gt
  if (gt !== null && num <= parseFloat(gt)) {
    return "under";
  }
  // Check that value is not greater than or equal to the lt
  if (lt !== null && num >= parseFloat(lt)) {
    return "over";
  }
  // Check that the value is not less than the min
  if (gt === null && min !== null && num < parseFloat(min)) {
    return "under";
  }
  // Check that value is not greater than the max
  if (lt === null && max !== null && num > parseFloat(max)) {
    return "over";
  }
  return false;
}

export function wrongLength(attrs: WrongLengthAttributes): RangeResponseType {
  const { max, min, value } = attrs;

  // Make sure field has value
  if (!value) {
    return false;
  }

  // Check validity
  const { length } = value;
  if (max !== null && length > parseFloat(max)) {
    return "over";
  }
  if (min !== null && length < parseFloat(min)) {
    return "under";
  }
  return false;
}

export function typeMismatch(attrs: ITypeMismatchAttributes): boolean {
  const { type, value } = attrs;

  // Make sure field has value
  if (!value) {
    return false;
  }

  // Check for the type
  switch (type) {
    case "number": {
      // Mismatch if we can't parse it as a float
      const parsed = parseFloat(value);
      return Number.isNaN(parsed);
    }
    case "boolean": {
      // Mismatch if the value isn't 'true' or 'false'
      const normalised = value.toLowerCase().trim();
      return normalised !== "true" && normalised !== "false";
    }
    default:
      // Never a mismatch
      return false;
  }
}
