import BaseController from "decor/base_controller";

export default class DataTableCellController extends BaseController {
  public handleCellClickToStopPropagation(e: MouseEvent) {
    // If stop_propagation is set, don't propagate the click event.
    e.stopPropagation();
  }

  public handleLinkClick(e: MouseEvent) {
    // This is called when the anchor itself was clicked.
    // We don't want this to be handled by JS, to preserve ctrl-click etc.
    e.stopPropagation();
  }
}
