import BaseController from "decor/base_controller";
import { replaceContentsWithChildren } from "lib/util/replace_with_dom_nodes";
import { FlashShowEvent } from "lib/types";

const INITIAL_CLASSES = "invisible opacity-0";
const VISIBLE_CLASSES = "transition-opacity duration-300 opacity-100 visible";
const COLLAPSED_CLASS = "hidden";
const TEXT_CLASS = "c-text-subtitle";

export default class FlashController extends BaseController {
  private hidden: boolean = true;

  public onInitialize() {
    this.onConnect(() => {
      if (this.data.get("showInitial")) {
        this.hidden = false;
        this.reveal();
      }
    });

    return super.onInitialize();
  }

  public handleCloseEvent() {
    this.hide();
  }

  // Reveal by calling:
  // window.dispatchEvent(new CustomEvent("cnf-flash:show", { detail: { message } }));
  public async handleShowEvent(evt: Event) {
    const {
      detail: { message, timeout },
    } = evt as FlashShowEvent;
    this.showFlashMessage(message, timeout);
  }

  public get isRevealed() {
    return !this.hidden;
  }

  public set text(text: string) {
    const p = document.createElement("p");
    TEXT_CLASS.split(" ").forEach((cl) => p.classList.add(cl));
    p.textContent = text;
    this.content = p;
  }

  public renderHeadingWithListContent(heading: string, items: string[]) {
    const headingNode = this.createHeading(heading);

    const listNode = document.createElement("ul");
    items.forEach((item) => {
      const listItemNode = document.createElement("li");
      listItemNode.textContent = item;
      listNode.appendChild(listItemNode);
    });

    this.content = [headingNode, listNode];
  }

  public showFlashMessage = (text: string, timeout?: number) => {
    this.text = text;
    this.reveal(timeout);
  };

  public reveal = (timeout?: number) => {
    this.removeInitialClass();
    this.addVisibleClasses();
    this.removeCollapsedClasses();
    if (timeout) {
      setTimeout(this.hide, timeout);
    }
    this.hidden = false;
    this.element.removeAttribute("hidden");
  };

  public hide = () => {
    this.removeVisibleClasses();
    this.addInitialClasses();
    this.hidden = true;
    this.element.setAttribute("hidden", "hidden");
  };

  public toggle = () => {
    if (this.hidden) {
      this.reveal();
    } else {
      this.hide();
    }
  };

  private removeVisibleClasses() {
    VISIBLE_CLASSES.split(" ").forEach((cl) =>
      this.element.classList.remove(cl),
    );
  }

  private addVisibleClasses() {
    VISIBLE_CLASSES.split(" ").forEach((cl) => this.element.classList.add(cl));
  }

  private removeCollapsedClasses() {
    COLLAPSED_CLASS.split(" ").forEach((cl) =>
      this.element.classList.remove(cl),
    );
  }

  private removeInitialClass() {
    INITIAL_CLASSES.split(" ").forEach((cl) =>
      this.element.classList.remove(cl),
    );
  }

  private addInitialClasses() {
    INITIAL_CLASSES.split(" ").forEach((cl) => this.element.classList.add(cl));
  }

  private createHeading(content: string) {
    const heading = document.createElement("h2");
    heading.classList.add("c-h7");
    heading.textContent = content;
    return heading;
  }

  private set content(children: Node | Node[]) {
    replaceContentsWithChildren(this.element, children);
  }
}
