import { Navigo__SideNavbarSubItemControllerIdentifier } from "controllers/identifiers";
import BaseController from "decor/base_controller";
import SideNavbarSubItemController from "navigo/side_navbar_sub_item_controller";

export default class SideNavbarItemController extends BaseController {
  public static outlets = [Navigo__SideNavbarSubItemControllerIdentifier];
  private declare readonly navigoSideNavbarSubItemOutlets: SideNavbarSubItemController[];
  private get subMenuItemControllers() {
    return this.navigoSideNavbarSubItemOutlets;
  }

  public static targets = ["title", "arrow", "subMenu"];

  private declare readonly arrowTarget: HTMLButtonElement;
  private declare readonly hasArrowTarget: boolean;
  private declare readonly titleTarget: HTMLElement;
  private declare readonly hasTitleTarget: boolean;
  private declare readonly subMenuTarget: HTMLElement;
  private declare readonly hasSubMenuTarget: boolean;

  public static classes = [
    "closed",
    "open",
    "entering",
    "leaving",
    "arrowUp",
    "arrowDown",
    "shown",
    "filtered",
  ];
  protected declare readonly closedClasses: string[];
  protected declare readonly openClasses: string[];
  protected declare readonly enteringClasses: string[];
  protected declare readonly leavingClasses: string[];
  protected declare readonly arrowUpClasses: string[];
  protected declare readonly arrowDownClasses: string[];
  protected declare readonly filteredClasses: string[];
  protected declare readonly shownClasses: string[];

  private subMenuOpen = false;

  public onInitialize() {
    this.onConnect(() => {
      this.subMenuOpen = this.data.get("selected") == "true";
    });

    return super.onInitialize();
  }

  public buttonClicked() {
    this.subMenuOpen = !this.subMenuOpen;
    this.animateSubMenu();
  }

  public expand() {
    this.subMenuOpen = true;
    this.animateSubMenu();
  }

  public collapse() {
    this.subMenuOpen = false;
    this.animateSubMenu();
  }

  public filterOnSearch(keyword: string): boolean {
    const matchesSubItems =
      this.subMenuItemControllers.filter((item: SideNavbarSubItemController) =>
        item.filterOnSearch(keyword),
      ).length > 0;

    // If anything inside this item matches, expand it
    if (matchesSubItems) {
      this.expand();
    }

    if (!matchesSubItems) {
      const titleMatch = this.toggleOnSearch(
        keyword,
        this.element,
        [this.titleTarget],
        this.shownClasses,
        this.filteredClasses,
      );
      // If the title matches, make sure all sub are shown
      this.subMenuItemControllers.forEach(
        (item: SideNavbarSubItemController) => {
          item.filterOnSearch("");
        },
      );
      return titleMatch;
    } else {
      this.setTargetElementClasses(
        this.element,
        this.filteredClasses,
        this.shownClasses,
      );
    }
    return matchesSubItems;
  }

  private animateSubMenu() {
    if (this.hasSubMenuTarget) {
      this.toggleTargetElementClasses(
        this.subMenuTarget,
        this.subMenuOpen,
        this.closedClasses,
        this.openClasses,
      );
      setTimeout(() => {
        this.toggleTargetElementClasses(
          this.subMenuTarget,
          this.subMenuOpen,
          this.leavingClasses,
          this.enteringClasses,
        );
      }, 100);
    }

    if (this.hasArrowTarget) {
      this.toggleTargetElementClasses(
        this.arrowTarget,
        this.subMenuOpen,
        this.arrowDownClasses,
        this.arrowUpClasses,
      );
    }
  }
}
