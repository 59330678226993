import ProductLightboxController from "mercor/product_lightbox_controller";
import axios from "lib/axios";
import BaseController from "decor/base_controller";
import { closeModal, showConfirmModal } from "lib/overlay/modal";
import { ModalLifecycleEvent } from "decor/modal_controller";
import { Mercor__ProductLightboxControllerIdentifier } from "controllers/identifiers";

export default class CartItemController extends BaseController {
  public static outlets = [Mercor__ProductLightboxControllerIdentifier];
  private declare readonly mercorProductLightboxOutlets: ProductLightboxController[];
  private get lightboxControllers() {
    return this.mercorProductLightboxOutlets;
  }

  public edit() {
    this.lightboxControllers.forEach((controller) => {
      // controller.showPurchaseConfigurator(); ???
    });
  }

  public removalWithoutConfirm() {
    this.deleteCartItem();
  }

  public showRemovalConfirm() {
    showConfirmModal({
      defaultReason: "ok",
      negativeButtonReason: "cancel",
      negativeButtonLabel: "No",
      positiveButtonReason: "ok",
      positiveButtonLabel: "Yes",
      message: "Are you sure that you want to delete this item?",
      onClosing: this.handleDialogClosing,
    });
  }

  private handleDialogClosing = ({
    detail: { closeReason },
  }: ModalLifecycleEvent) => {
    if (closeReason === "ok") {
      this.deleteCartItem();
    } else {
      closeModal();
    }
  };

  private deleteCartItem() {
    const url = this.data.get("url");

    if (url === null) {
      return;
    }

    axios
      .delete(url)
      .then(() => {
        window.location.reload();
      })
      .catch((e: Error) => {
        console.error("Could not delete item from cart... " + url);
        console.log(e.stack);
        console.log(e.message);
      });
  }
}
