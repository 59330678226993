import DataTableSearchAndFilterController from "decor/search_and_filter_controller";
import flatpickr from "flatpickr";
import { Instance } from "flatpickr/dist/types/instance";

export default class ReportFiltersController extends DataTableSearchAndFilterController {
  public static readonly targets =
    DataTableSearchAndFilterController.targets.concat(["calendarInput"]);

  public declare readonly calendarInputTarget: HTMLInputElement;
  public declare readonly hasCalendarInputTarget: boolean;

  public previousDateRange() {
    const currentStep = parseInt(this.getRequiredDataAttr("dateRangeStep"), 10);
    const previous = currentStep - 1;
    this.addFilterParam("date_range_step", previous.toString());
    this.handleApply(false);
  }

  public nextDateRange() {
    const currentStep = parseInt(this.getRequiredDataAttr("dateRangeStep"), 10);
    const next = currentStep + 1;
    this.addFilterParam("date_range_step", next.toString());
    this.handleApply(false);
  }

  public handleRangePicker(evt: Event) {
    const datePicker = flatpickr(this.calendarInputTarget as HTMLInputElement, {
      mode: "range",
      onClose: (selectedDates: Date[], dateStr: string, instance: Instance) => {
        if (selectedDates.length == 2) {
          this.addFilterParam(
            "custom_range",
            selectedDates
              .map((d) => {
                return instance.formatDate(new Date(d), "Y-m-d");
              })
              .join("_"),
          );
          this.getFilterValues();
          this.clearStep();
          this.addFilterParam("period", "custom_range");
          this.reloadAndSetQueryParams();
        }
      },
    });
    datePicker.open();
  }

  private clearStep() {
    this.addFilterParam("date_range_step", undefined);
  }

  public handleApply(clearStep: boolean = true) {
    this.getFilterValues();
    if (clearStep) this.clearStep();
    // If time period is not custom_range then remove custom_range values
    if (
      this.filters["period"] !== "custom_range" &&
      this.filters["custom_range"]
    ) {
      this.filters["custom_range"] = undefined;
    }
    if (this.hasApplyButtonTarget) {
      this.applyButtonTarget.disabled = true;
    }
    this.reloadAndSetQueryParams();
  }
}
