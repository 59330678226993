import BaseController from "decor/base_controller";
import axios from "lib/axios";
import { markAsSafeHTML, safelySetInnerHTML } from "lib/util/safe_html";
import { replaceContentsWithChildren } from "lib/util/replace_with_dom_nodes";

export default abstract class NavbarWithSearchController extends BaseController {
  public static targets = [
    "searchInput",
    "searchDropdown",
    "searchDropdownContent",
    "searchSpinner",
  ];

  protected declare readonly searchInputTarget: HTMLInputElement;
  protected declare readonly searchDropdownTarget: HTMLDivElement;
  protected declare readonly searchDropdownContentTarget: HTMLDivElement;
  protected declare readonly searchSpinnerTarget: HTMLDivElement;

  protected windowClickHandlerAdded = false;
  protected debounceSearch?: any = undefined;
  protected hasSearchResults = false;

  public clickedSearchInput(event: Event) {
    event.stopPropagation();
  }

  public clickedSearchContent(event: Event) {
    event.stopPropagation();
  }

  public async search() {
    const val = this.searchText();

    if (this.debounceSearch) {
      clearTimeout(this.debounceSearch);
    }
    this.debounceSearch = setTimeout(() => {
      this.debounceSearch = undefined;
      if (!val) {
        this.hasSearchResults = false;
        this.dropdownShow(false);
        return;
      }
      this.hasSearchResults = true;
      this.dropdownShow(true);
      this.getResultsAndShow(val);
    }, 300);
  }

  public handleWindowClick = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    this.dropdownShow(false);
  };

  protected searchText() {
    return this.searchInputTarget.value.trim().toLowerCase();
  }

  protected getResultsAndShow(searchKey: string) {
    axios
      .get<string>(
        this.getRequiredDataAttr("searchUrl") + "?search_key=" + searchKey,
        {
          headers: {
            "Content-Type": "text/html",
          },
        },
      )
      .then((response) => {
        const content = markAsSafeHTML(response.data);
        const contentContainer = document.createElement("div");
        safelySetInnerHTML(contentContainer, content);
        replaceContentsWithChildren(
          this.searchDropdownContentTarget,
          contentContainer,
        );
        this.setTargetElementClasses(
          this.searchDropdownContentTarget,
          ["hidden"],
          [],
        );
        this.setTargetElementClasses(this.searchSpinnerTarget, [], ["hidden"]);
      });
  }

  protected dropdownShow(show: boolean) {
    this.toggleTargetElementTransitionClasses(
      this.searchDropdownTarget,
      show,
      ["transform", "ease-in", "duration-100"],
      ["opacity-0", "scale-95"],
      ["opacity-100", "scale-100"],
      ["transform", "ease-out", "duration-200"],
      ["opacity-100", "scale-100"],
      ["opacity-0", "scale-95"],
    );
    if (show) {
      this.setTargetElementClasses(this.searchDropdownTarget, ["hidden"], []);
      if (!this.windowClickHandlerAdded) {
        window.addEventListener("click", this.handleWindowClick);
        this.windowClickHandlerAdded = true;
      }
    } else {
      setTimeout(() => {
        this.setTargetElementClasses(this.searchDropdownTarget, [], ["hidden"]);
        if (this.windowClickHandlerAdded) {
          window.removeEventListener("click", this.handleWindowClick);
          this.windowClickHandlerAdded = false;
        }
      }, 200);
    }
  }
}
