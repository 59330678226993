import BaseController from "decor/base_controller";

export default class DeliveryDateSelectController extends BaseController {
  public static targets = ["form"];
  private declare readonly formTarget: HTMLFormElement;

  public dateSelected(evt: Event) {
    evt.stopPropagation();
    this.formTarget.submit();
  }
}
