import {
  FormControlElement,
  FormControlErrors,
  FormControlValidationEvent,
  FormFieldError,
} from "lib/types";

import FormControlController from "decor/forms/form_control_controller";
import { localeMessage } from "lib/i18n";
import { generateFormValidationMessage } from "lib/util/form_validation_messages";

import BaseController from "decor/base_controller";
import HelperTextSectionController from "decor/forms/helper_text_section_controller";
import ErrorIconSectionController from "decor/forms/error_icon_section_controller";
import FormFieldLayoutController from "decor/forms/form_field_layout_controller";

export default abstract class FormFieldController extends BaseController {
  public static targets = ["input", "container"];

  protected declare readonly inputTarget: HTMLInputElement;
  protected declare readonly hasInputTarget: boolean;
  protected declare readonly containerTarget: HTMLDivElement;
  protected declare readonly hasContainerTarget: HTMLDivElement;

  public static classes = [
    "valid",
    "invalid",
    "validInput",
    "invalidInput",
    "validContainer",
    "invalidContainer",
  ];
  protected declare readonly validClasses: string[];
  protected declare readonly invalidClasses: string[];
  protected declare readonly validInputClasses: string[];
  protected declare readonly invalidInputClasses: string[];
  protected declare readonly validContainerClasses: string[];
  protected declare readonly invalidContainerClasses: string[];

  // Can be overridden with the 'invalid' class name modifier for this form field.
  protected get invalidClassName() {
    return "invalid";
  }

  public handleControlValidatedEvent(evt: FormControlValidationEvent) {
    const {
      target,
      detail: { errors, valid },
    } = evt;
    this.handleValidationResponse(target, valid, errors);
  }

  public get valid() {
    return this.validate().valid;
  }

  public validate() {
    // Ask the form control to carry out the validation.
    const fc = this.fieldControl;
    const { valid, errors: controlErrors } = fc.validate();

    // Convert the errors into human-readable and return.
    const errors = this.handleValidationResponse(
      fc.formControlElement,
      valid,
      controlErrors,
    );
    return {
      errors,
      valid,
    };
  }

  public focusControl() {
    // Proxy through to field_control.
    this.fieldControl.focusControl();
  }

  public get name() {
    return this.inputTarget.name;
  }

  public get value() {
    return this.inputTarget.value;
  }

  public set value(val: string) {
    this.inputTarget.value = val;
  }

  public get disabled() {
    // Proxy through to field_control.
    // May need to override/augment on a case-by-case basis.
    return this.fieldControl.disabled;
  }

  public set disabled(value) {
    // Proxy through to field_control.
    // May need to override/augment on a case-by-case basis.
    this.fieldControl.disabled = value;
    this.toggleTargetElementClasses(this.element, value, [], ["disabled"]);
  }

  public get required() {
    // Proxy through to field_control.
    // May need to override/augment on a case-by-case basis.
    return this.fieldControl.required;
  }

  public set required(value) {
    // Proxy through to field_control.
    // May need to override/augment on a case-by-case basis.
    this.fieldControl.required = value;
  }

  protected set valid(isValid: boolean) {
    this.toggleTargetElementClasses(
      this.element,
      isValid,
      this.invalidClasses,
      this.validClasses,
    );
    if (this.hasContainerTarget) {
      this.toggleTargetElementClasses(
        this.containerTarget,
        isValid,
        this.invalidContainerClasses,
        this.validContainerClasses,
      );
    }
    if (this.hasInputTarget) {
      this.toggleTargetElementClasses(
        this.inputTarget,
        isValid,
        this.invalidInputClasses,
        this.validInputClasses,
      );
    }

    if (this.fieldLayoutControl) {
      this.fieldLayoutControl.valid = isValid;
    }
  }

  protected generateErrorMessage(
    customMessageKey: string,
    defaultMessageKey: string,
    values = {},
  ) {
    const rawMessage =
      this.data.get(customMessageKey) ||
      this.generateDefaultMessage(defaultMessageKey);
    return generateFormValidationMessage(rawMessage, values);
  }

  private get fieldControl(): FormControlController {
    const fieldControl = this.getSpecificChildController<FormControlController>(
      FormControlController,
    );
    if (!fieldControl) {
      throw new Error(
        "Could not find a form control child inside this form field. Please check the DOM structure.",
      );
    }
    return fieldControl;
  }

  private get fieldLayoutControl() {
    return this.getSpecificChildController<FormFieldLayoutController>(
      FormFieldLayoutController,
    );
  }

  private handleValidationResponse(
    fieldControlTarget: FormControlElement,
    isValid: boolean,
    errors: FormControlErrors,
  ) {
    this.valid = isValid;
    const parsedErrors = this.parseErrorMessages(errors);

    if (this.fieldLayoutControl) {
      this.fieldLayoutControl.errorTextContent =
        parsedErrors.length > 0 ? parsedErrors[0].message : null;
    }

    // Push out an event that a higher controller can respond to
    this.emitFormFieldValidatedEvent(fieldControlTarget, isValid, parsedErrors);

    return parsedErrors;
  }

  private parseErrorMessages(errors: FormControlErrors): FormFieldError[] {
    const errorResponse = [];
    if (errors.missingValue) {
      errorResponse.push({
        code: "missingValue",
        message: this.generateErrorMessage(
          "validationMessageRequired",
          "blank",
        ),
      });
    }
    if (errors.wrongLength === "over") {
      errorResponse.push({
        code: "lengthOver",
        message: this.generateErrorMessage(
          "validationMessageLengthOver",
          "invalid",
        ),
      });
    }
    if (errors.wrongLength === "under") {
      errorResponse.push({
        code: "lengthUnder",
        message: this.generateErrorMessage(
          "validationMessageLengthUnder",
          "invalid",
        ),
      });
    }
    if (errors.outOfRange === "over") {
      errorResponse.push({
        code: "rangeOver",
        message: this.generateErrorMessage(
          "validationMessageRangeOver",
          "invalid",
        ),
      });
    }
    if (errors.outOfRange === "under") {
      errorResponse.push({
        code: "rangeUnder",
        message: this.generateErrorMessage(
          "validationMessageRangeUnder",
          "invalid",
        ),
      });
    }
    // Pattern matching comes last.
    // Numerical values often have a pattern attribute to bring up the correct on-screen keyboard,
    // but in many cases we'd rather the (more specific) range message be displayed first.
    if (errors.patternMismatch) {
      errorResponse.push({
        code: "patternMismatch",
        message: this.generateErrorMessage(
          "validationMessagePatternMismatch",
          "invalid",
        ),
      });
    }
    if (errors.typeMismatch) {
      errorResponse.push({
        code: "typeMismatch",
        message: this.generateErrorMessage(
          "validationMessageTypeMismatch",
          "invalid",
        ),
      });
    }
    return errorResponse;
  }

  private generateDefaultMessage(key: string) {
    return `${this.label} ${localeMessage(key)}`;
  }

  private get label() {
    const label = this.data.get("label");
    if (!label) {
      throw new Error(`${this.identifier} should define a label attribute`);
    }
    return label;
  }

  private emitFormFieldValidatedEvent(
    target: FormControlElement,
    valid: boolean,
    errors: FormFieldError[],
  ) {
    this.dispatch("validated", {
      target,
      bubbles: true,
      cancelable: false,
      detail: {
        errors,
        valid,
      },
    });
  }
}
