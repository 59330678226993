import BaseController from "decor/base_controller";

export default class HelperTextSectionController extends BaseController {
  public static targets = ["helperText", "errorText"];

  protected declare readonly helperTextTarget: HTMLParagraphElement;
  protected declare readonly hasHelperTextTarget: boolean;
  protected declare readonly errorTextTarget: HTMLDivElement;
  protected declare readonly hasErrorTextTarget: boolean;

  public set errorText(errorMessage: string | null) {
    if (!this.hasErrorTextTarget) return;

    if (errorMessage) {
      this.errorTextTarget.textContent = errorMessage;
    }
    this.toggleHelpError = errorMessage === null;
  }

  public set toggleHelpError(showHelp: boolean) {
    if (this.hasHelperTextTarget) {
      this.toggleTargetElementClasses(
        this.helperTextTarget,
        showHelp &&
          !!(
            this.helperTextTarget.textContent &&
            this.helperTextTarget.textContent.trim() != ""
          ),
        ["hidden"],
        [],
      );
    }

    if (this.hasErrorTextTarget) {
      this.toggleTargetElementClasses(
        this.errorTextTarget,
        showHelp,
        [],
        ["hidden"],
      );
    }
  }
}
