import BaseController from "decor/base_controller";
import { addParametersToQueryString } from "lib/util/add_parameter_to_url";

export default class DataTableHeaderCellController extends BaseController {
  public handleSortableClick() {
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      sort_by: this.sortKey,
      // eslint-disable-next-line @typescript-eslint/camelcase
      sorted_direction: this.nextSortDirection,
    };
    window.location.search = addParametersToQueryString(
      window.location.search,
      params,
    );
  }

  private get sortKey() {
    return this.getRequiredDataAttr("sortKey");
  }

  private get sortDirection() {
    return this.data.get("sortedDirection");
  }

  private get nextSortDirection() {
    return this.sortDirection === "desc" ? "asc" : "desc";
  }
}
