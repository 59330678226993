import BaseController from "decor/base_controller";
import { closeModal, showModal } from "lib/overlay/modal";
import { markAsSafeHTML } from "lib/util/safe_html";
import { addParametersToQueryString } from "lib/util/add_parameter_to_url";

function getFormElementValueByName(
  form: Element,
  namePrefix: string,
  name: string,
  checked = false,
) {
  const el = form.querySelector(
    `input[name='${namePrefix}[${name}]']${checked ? ":checked" : ""}`,
  ) as HTMLSelectElement | null;
  const selectEl = form.querySelector(
    `select[name='${namePrefix}[${name}]']`,
  ) as HTMLSelectElement | null;
  if (el) {
    return el.value;
  } else if (selectEl) {
    return selectEl.options[selectEl.selectedIndex].value;
  } else {
    return "";
  }
}

export default class AddNoteController extends BaseController {
  public static targets = ["notes", "triggerButton"];

  private declare readonly notesTarget: HTMLInputElement;
  private declare readonly triggerButtonTarget: HTMLInputElement;

  public handleButtonClick(evt: Event) {
    // Stop propagation: prevent any clicks leaking out
    evt.preventDefault();
    evt.stopPropagation();

    let params = {};
    if (this.data.get("lightbox") === "true") {
      const namePrefix = this.data.get("formName");
      const form = this.findParentElementByTagName(this.notesTarget, "form");
      if (!form || !namePrefix) return;

      params = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        chosen_variant: getFormElementValueByName(
          form,
          namePrefix,
          "chosen_variant",
          true,
        ),
        // eslint-disable-next-line @typescript-eslint/camelcase
        portion_size: getFormElementValueByName(
          form,
          namePrefix,
          "portion_size",
        ),
        quantity: getFormElementValueByName(form, namePrefix, "quantity"),
        // eslint-disable-next-line @typescript-eslint/camelcase
        delivery_date: getFormElementValueByName(
          form,
          namePrefix,
          "delivery_date",
        ),
      };
    }

    // Show the dialog
    const [contentHref, placeholder] = this.getRequiredDataAttrs(
      "contentHref",
      "placeholder",
    );
    showModal({
      onClosing: (e) => {
        if (e.detail.closeReason === "save") {
          this.notesTarget.value = (
            document.getElementsByName(
              "order_note[note]",
            )[0] as HTMLInputElement
          ).value;
        } else if (e.detail.closeReason === "delete") {
          this.notesTarget.value = "";
        }

        if (this.data.get("lightbox") === "true") {
          // Reopen the lightbox of the product
          const [lightboxContentHref, lightboxPlaceholder] =
            this.getRequiredDataAttrs("lightboxContentHref", "placeholder");
          return setTimeout(() => {
            return showModal({
              contentHref: `${lightboxContentHref}?${addParametersToQueryString(
                "",
                {
                  notes: this.notesTarget.value,
                  ...params,
                },
              )}`,
              closeOnOverlayClick: true,
              placeholder: markAsSafeHTML(lightboxPlaceholder),
            });
          }, 400); // FIXME: we need a better way.. currently after 200ms of closing a dialog a callback
          // sets the dialog to hidden (to allow for fadeout animation) but if we re-show in less than 200ms
          // we are immediately hidden again by the callback
        }
        const buttonElement = this.triggerButtonTarget;
        if (buttonElement) {
          if (this.notesTarget.value) {
            // tslint:disable-next-line:no-inner-html
            buttonElement.innerHTML =
              '<span class="text-center"><svg fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="decor--icon inline mr-1 h-4 w-4 -my-2 text-gray-700" role="img"><path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg><span class="">Edit note to seller</span></span>';
          } else {
            // tslint:disable-next-line:no-inner-html
            buttonElement.innerHTML =
              '<span class="text-center"><svg fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" id="decor--icon-4799670-17" class="decor--icon inline mr-1 h-4 w-4 -my-2 text-gray-700" height="24" width="24" role="img"><path stroke-linecap="round" stroke-linejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-5l-5 5v-5z"></path></svg><span class="">Add note to seller</span></span>';
          }
        }
      },
      contentHref:
        contentHref + "?note=" + encodeURIComponent(this.notesTarget.value),
      placeholder: markAsSafeHTML(placeholder),
    });
  }
}
