import { Navigo__SideNavbarItemControllerIdentifier } from "controllers/identifiers";
import BaseController from "decor/base_controller";
import SideNavbarItemController from "navigo/side_navbar_item_controller";

export default class SideNavbarSectionController extends BaseController {
  public static outlets = [Navigo__SideNavbarItemControllerIdentifier];
  private declare readonly navigoSideNavbarItemOutlets: SideNavbarItemController[];
  private get itemControllers() {
    return this.navigoSideNavbarItemOutlets;
  }

  public static targets = ["title"];
  private declare readonly titleTarget: HTMLParagraphElement;
  private declare readonly hasTitleTarget: boolean;

  public static classes = ["shown", "filtered"];
  protected declare readonly filteredClasses: string[];
  protected declare readonly shownClasses: string[];

  public filterOnSearch(keyword: string): boolean {
    const matchesItems =
      this.itemControllers.filter((item: SideNavbarItemController) =>
        item.filterOnSearch(keyword),
      ).length > 0;

    if (!matchesItems && this.hasTitleTarget) {
      // Section has no matching items and a title
      const matchedOnTitle = this.toggleOnSearch(
        keyword,
        this.element,
        [this.titleTarget],
        this.shownClasses,
        this.filteredClasses,
      );
      // If title of section matches, the show it all
      if (matchedOnTitle) {
        this.itemControllers.forEach((item: SideNavbarItemController) =>
          item.filterOnSearch(""),
        );
      }
      return matchedOnTitle;
    } else if (!matchesItems) {
      // We dont match items in the section and it has no title, so hide it
      this.setTargetElementClasses(
        this.element,
        this.shownClasses,
        this.filteredClasses,
      );
    } else {
      this.setTargetElementClasses(
        this.element,
        this.filteredClasses,
        this.shownClasses,
      );
    }
    return matchesItems;
  }
}
