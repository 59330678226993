import BaseController from "decor/base_controller";
import {
  addParametersToQueryString,
  removeParameterFromQueryString,
} from "lib/util/add_parameter_to_url";
import { Keys, keyWasPressed } from "lib/util/keyboard_key";
import flatpickr from "flatpickr";
import { Instance } from "flatpickr/dist/types/instance";
import DropdownController from "decor/dropdown_controller";
import FormFieldController from "decor/forms/form_field_controller";
import CheckboxController from "decor/forms/checkbox_controller";
import {
  Decor__DropdownControllerIdentifier,
  Decor__Forms__CheckboxControllerIdentifier,
  Decor__Forms__SelectControllerIdentifier,
  Decor__Forms__TextFieldControllerIdentifier,
} from "controllers/identifiers";
import SelectController from "./forms/select_controller";
import TextFieldController from "./forms/text_field_controller";

export default class SearchAndFilterController extends BaseController {
  public static readonly targets = [
    "searchInput",
    "applyButton",
    "clearFiltersButton",
  ];

  public declare readonly searchInputTarget: HTMLInputElement;
  public declare readonly hasSearchInputTarget: boolean;
  public declare readonly applyButtonTarget: HTMLButtonElement;
  public declare readonly hasApplyButtonTarget: boolean;
  public declare readonly clearFiltersButtonTarget: HTMLButtonElement;
  public declare readonly hasClearFiltersButtonTarget: boolean;

  public static readonly outlets = [
    Decor__Forms__CheckboxControllerIdentifier,
    Decor__Forms__SelectControllerIdentifier,
    Decor__Forms__TextFieldControllerIdentifier,
    Decor__DropdownControllerIdentifier,
  ];
  private declare readonly decorDropdownOutlet: DropdownController;
  private declare readonly decorFormsCheckboxOutlets: CheckboxController[];
  private declare readonly decorFormsSelectOutlets: SelectController[];
  private declare readonly decorFormsTextFieldOutlets: TextFieldController[];
  private get dropdownController() {
    return this.decorDropdownOutlet;
  }
  private get checkboxControllers() {
    return this.decorFormsCheckboxOutlets;
  }
  private get selectControllers() {
    return this.decorFormsSelectOutlets;
  }
  private get textFieldControllers() {
    return this.decorFormsTextFieldOutlets;
  }

  protected filters: { [key: string]: string | undefined } = {};

  public toggle(event: Event) {
    this.dropdownController.toggle(event);
    event.stopPropagation();
  }

  public hide() {
    this.dropdownController.hide();
  }

  public hideOnClickOutside(event: Event) {
    this.dropdownController.hideOnClickOutside(event);
  }

  public handleClearFilters() {
    this.getFilterValues();
    if (this.hasClearFiltersButtonTarget) {
      this.clearFiltersButtonTarget.disabled = true;
    }
    // Clear the filters
    this.filters = Object.entries(this.filters).reduce<any>(
      (acc, [name, _value]) => {
        acc[name] = undefined;
        return acc;
      },
      {},
    );
    this.reloadAndSetQueryParams();
  }

  public handleApply() {
    this.getFilterValues();
    if (this.hasApplyButtonTarget) {
      this.applyButtonTarget.disabled = true;
    }
    this.reloadAndSetQueryParams();
  }

  protected getFilterValues() {
    if (this.hasSearchInputTarget && this.searchInputTarget.value) {
      const { name, value } = this.searchInputTarget;
      this.addFilterParam(name, value);
    }
    this.checkboxControllers.forEach((controller) => {
      this.addFilterParam(
        controller.name,
        controller.checked ? "true" : undefined,
      );
    });
    this.selectControllers.forEach((controller) => {
      this.addFilterParam(controller.name, controller.value);
    });
    this.textFieldControllers.forEach((controller) => {
      this.addFilterParam(controller.name, controller.value);
    });
  }

  public handleRangePicker(evt: Event) {
    const datePicker = flatpickr(evt.target as HTMLInputElement, {
      mode: "range",
      onClose: (selectedDates: Date[], dateStr: string, instance: Instance) => {
        if (selectedDates.length == 2) {
          this.addFilterParam(
            "custom_range",
            selectedDates
              .map((d) => {
                return instance.formatDate(new Date(d), "Y-m-d");
              })
              .join("_"),
          );
        }
      },
    });
    datePicker.open();
  }

  // https://medium.com/@uistephen/keyboardevent-key-for-cross-browser-key-press-check-61dbad0a067a
  public handleSearchInputKeydown(evt: KeyboardEvent) {
    if (evt.defaultPrevented) {
      return;
    }
    const enterPressed = keyWasPressed(evt, Keys.Enter);
    if (enterPressed) {
      // Stop form submission
      evt.preventDefault();

      this.searchInputTarget.disabled = true;
      this.handleApply();
    }
  }

  protected addFilterParam(name: string, value?: string) {
    this.filters[name] = value;
  }

  protected reloadAndSetQueryParams() {
    // Reset to page 1
    this.filters["page"] = undefined;
    window.location.search = Object.entries(this.filters).reduce(
      (params, filter) => {
        const [name, value] = filter;
        if (value === undefined) {
          return removeParameterFromQueryString(params, name);
        } else {
          return addParametersToQueryString(params, {
            [name]: value,
          });
        }
      },
      window.location.search,
    );
  }
}
