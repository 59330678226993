import ModalController, { ModalLifecycleEvent } from "decor/modal_controller";
import { SafeHTMLContent } from "lib/types";
import { safelySetInnerHTML } from "lib/util/safe_html";

export interface ModalConfirmOptions {
  title?: string;
  message?: string;
  messageHTML?: SafeHTMLContent;
  defaultReason?: string;
  negativeButtonReason?: string;
  negativeButtonLabel?: string;
  positiveButtonReason?: string;
  positiveButtonLabel?: string;
  closeOnOverlayClick?: boolean;
  onClosing?: (evt: ModalLifecycleEvent) => void;
}

export enum ModalConfirmEvents {
  Open = "decor--confirm-modal:open",
  Opening = "decor--confirm-modal:opening",
  Ready = "decor--confirm-modal:ready",
  Opened = "decor--confirm-modal:opened",
  Close = "decor--confirm-modal:close",
  Closing = "decor--confirm-modal:closing",
  Closed = "decor--confirm-modal:closed",
}

export default class ConfirmModalController extends ModalController {
  public static targets = [
    "overlay",
    "modal",
    "title",
    "message",
    "negativeButton",
    "positiveButton",
  ];

  private declare readonly titleTarget: HTMLHeadingElement;
  private declare readonly messageTarget: HTMLParagraphElement;
  private declare readonly positiveButtonTarget: HTMLButtonElement;
  private declare readonly negativeButtonTarget: HTMLButtonElement;

  public negativeButtonReason: string | undefined;
  public positiveButtonReason: string | undefined;

  public negativeButton() {
    this.close(this.negativeButtonReason);
  }

  public positiveButton() {
    this.close(this.positiveButtonReason);
  }

  // Open the modal and load its content if needed
  public async open(showOptions: ModalConfirmOptions) {
    this.dispatchLifecycleEvent(ModalConfirmEvents.Opening, {
      shownWith: showOptions,
    });
    await this.prepareConfirmModalAndLoad(showOptions);
    this.dispatchLifecycleEvent(ModalConfirmEvents.Opened, {
      shownWith: showOptions,
    });
  }

  private prepareConfirmModalAndLoad(showOptions: ModalConfirmOptions) {
    const {
      title,
      message,
      messageHTML,
      defaultReason,
      positiveButtonLabel,
      positiveButtonReason,
      negativeButtonLabel,
      negativeButtonReason,
    } = showOptions;
    // Setup buttons etc
    this.closeOnOverlayClick = !!showOptions.closeOnOverlayClick;
    if (title) {
      this.titleTarget.innerText = title;
    }
    if (messageHTML) {
      safelySetInnerHTML(this.messageTarget, messageHTML);
    } else if (message) {
      this.messageTarget.innerText = message;
    }
    if (positiveButtonLabel) {
      this.positiveButtonTarget.innerText = positiveButtonLabel;
    }
    if (negativeButtonLabel) {
      this.negativeButtonTarget.innerText = negativeButtonLabel;
    }
    this.negativeButtonReason = negativeButtonReason;
    this.positiveButtonReason = positiveButtonReason;
    if (defaultReason) {
      if (defaultReason == this.negativeButtonReason) {
        this.negativeButtonTarget.focus();
      } else if (defaultReason == this.positiveButtonReason) {
        this.positiveButtonTarget.focus();
      }
    }
    this.reveal();
  }

  // Close the modal
  public close(closeReason?: string) {
    this.dispatchLifecycleEvent(ModalConfirmEvents.Closing, { closeReason });
    this.hide();
    this.dispatchLifecycleEvent(ModalConfirmEvents.Closed, { closeReason });
  }
}
