import { Decor__Tables__DataTableRowControllerIdentifier } from "controllers/identifiers";
import BaseController from "decor/base_controller";
import DataTableRowController from "decor/tables/data_table_row_controller";

export default class ModifyOrderFormController extends BaseController {
  public static outlets = [Decor__Tables__DataTableRowControllerIdentifier];
  private declare readonly decorTablesDataTableRowOutlets: DataTableRowController[];
  private get rowControllers() {
    return this.decorTablesDataTableRowOutlets;
  }

  public toggleDisableRow(evt: CustomEvent<{ rowId: string }>) {
    const { rowId } = evt.detail;
    const rowToToggle = this.rowControllers.find((rc) => rc.id === rowId);
    if (rowToToggle) {
      rowToToggle.disabled = !rowToToggle.disabled;
    }
  }
}
