import NumberFieldController from "decor/forms/number_field_controller";

export default class PriceModifierController extends NumberFieldController {
  public static targets = NumberFieldController.targets.concat([
    "priceModifierType",
    "priceModifierAmount",
  ]);

  private declare readonly priceModifierTypeTarget: HTMLInputElement;
  protected declare readonly hasPriceModifierTypeTarget: boolean;

  public onInitialize() {
    this.handlePriceModifierTypeChanges();

    return super.onInitialize();
  }

  public handlePriceModifierTypeChanges() {
    if (!this.hasPriceModifierTypeTarget) return;

    const type = this.priceModifierTypeTarget.value as
      | "fixed"
      | "percentage"
      | undefined;

    if (type === "fixed") {
      this.update(
        this.leadingTextAddOnTarget,
        this.getOptionalDataAttr("leadingTextFixed"),
      );
      this.update(
        this.trailingTextAddOnTarget,
        this.getOptionalDataAttr("trailingTextFixed"),
      );
    } else if (type === "percentage") {
      this.update(this.leadingTextAddOnTarget, "");
      this.update(this.trailingTextAddOnTarget, "%");
    }
  }

  private update(target: HTMLElement | null, text: string | undefined) {
    if (target) {
      target.textContent = text || "";
    }
  }
}
