import axios from "lib/axios";
import BaseController from "decor/base_controller";
import { localeMessage } from "lib/i18n";
import { SafeHTMLContent } from "lib/types";
import { replaceContentsWithChildren } from "lib/util/replace_with_dom_nodes";
import { markAsSafeHTML, safelySetInnerHTML } from "lib/util/safe_html";

export enum ModalEvents {
  Open = "decor--modal:open",
  Opening = "decor--modal:opening",
  Loading = "decor--modal:loading",
  Loaded = "decor--modal:loaded",
  Ready = "decor--modal:ready",
  Opened = "decor--modal:opened",
  Close = "decor--modal:close",
  Closing = "decor--modal:closing",
  Closed = "decor--modal:closed",
}

export interface ModalShowOptions {
  contentHref?: string;
  placeholder?: SafeHTMLContent;
  closeOnOverlayClick?: boolean;
  onClosing?: (evt: ModalLifecycleEvent) => void;
}

export interface ModalLifecycleEventDetail {
  shownWith?: ModalShowOptions;
  closeReason?: string;
  action?: string;
}

export type ModalLifecycleEvent = CustomEvent<ModalLifecycleEventDetail>;
export type ModalShowEvent = CustomEvent<ModalShowOptions>;

export default class ModalController extends BaseController {
  public static targets = ["overlay", "modal"];

  private declare readonly overlayTarget: HTMLDivElement;
  private declare readonly modalTarget: HTMLDivElement;

  public static classes = [
    "overlayEntering",
    "overlayEnteringFrom",
    "overlayEnteringTo",
    "overlayLeaving",
    "overlayLeavingFrom",
    "overlayLeavingTo",
    "modalEntering",
    "modalEnteringFrom",
    "modalEnteringTo",
    "modalLeaving",
    "modalLeavingFrom",
    "modalLeavingTo",
  ];

  protected declare readonly overlayEnteringClasses: string[];
  protected declare readonly overlayEnteringFromClasses: string[];
  protected declare readonly overlayEnteringToClasses: string[];
  protected declare readonly overlayLeavingClasses: string[];
  protected declare readonly overlayLeavingFromClasses: string[];
  protected declare readonly overlayLeavingToClasses: string[];
  protected declare readonly modalEnteringClasses: string[];
  protected declare readonly modalEnteringFromClasses: string[];
  protected declare readonly modalEnteringToClasses: string[];
  protected declare readonly modalLeavingClasses: string[];
  protected declare readonly modalLeavingFromClasses: string[];
  protected declare readonly modalLeavingToClasses: string[];

  private modalVisible = false;
  protected closeOnOverlayClick = false;

  public onInitialize() {
    this.onConnect(() => {
      if (this.data.get("showInitial")) {
        this.open({
          contentHref: this.data.get("contentHref"),
        } as ModalShowOptions);
      }
    });

    return super.onInitialize();
  }

  // Handle click on overlay to optionally close modal
  public overlayClicked() {
    if (
      this.closeOnOverlayClick ||
      this.data.get("closeOnOverlayClick") == "true"
    ) {
      this.close();
    }
  }

  // Reveal the dialog by triggering event (with stimulus or directly) on window
  // > window.dispatchEvent(new CustomEvent('decor-modal:open', { detail: { message } }));
  public handleCloseEvent(evt: ModalLifecycleEvent) {
    const action = evt.detail?.closeReason || evt.detail?.action;
    this.close(action);
  }

  // Reveal the dialog by calling:
  // window.dispatchEvent(new CustomEvent('decor-modal:show', { detail: { message } }));
  public async handleOpenEvent(evt: ModalShowEvent) {
    await this.open(evt.detail);
  }

  // Open the modal and load its content if needed
  public async open(showOptions: ModalShowOptions) {
    this.dispatchLifecycleEvent(ModalEvents.Opening, {
      shownWith: showOptions,
    });
    await this.prepareModalAndLoad(showOptions);
    this.dispatchLifecycleEvent(ModalEvents.Opened, { shownWith: showOptions });
  }

  // Close the modal
  public close(closeReason?: string) {
    this.dispatchLifecycleEvent(ModalEvents.Closing, { closeReason });
    // TODO: close callback?
    this.hide();
    this.dispatchLifecycleEvent(ModalEvents.Closed, { closeReason });
  }

  public get isVisible() {
    return this.modalVisible;
  }

  protected async prepareModalAndLoad(shownWith: ModalShowOptions) {
    const { contentHref, placeholder, closeOnOverlayClick } = shownWith;
    if (placeholder) {
      this.setContent(placeholder);
    }
    this.closeOnOverlayClick = !!closeOnOverlayClick;
    if (contentHref) {
      this.getContent(shownWith)
        .then((c) => {
          this.setContent(c);
        })
        .catch((err) => {
          console.error(
            "Could not fetch content for dropdown",
            contentHref,
            err,
          );
          const errorMessage = localeMessage("generic_server_error");
          // To prevent user being blocked, make sure they can exit the modal
          this.closeOnOverlayClick = true;
          this.setContent(markAsSafeHTML(errorMessage));
        });
    }
    this.reveal();
  }

  protected reveal() {
    this.modalVisible = true;
    this.setClasses();
  }

  protected hide() {
    this.modalVisible = false;
    this.setClasses();
  }

  private setClasses() {
    if (this.modalVisible) {
      this.setTargetElementClasses(this.element, ["hidden"], []);
    } else {
      setTimeout(() => {
        this.setTargetElementClasses(this.element, [], ["hidden"]);
      }, 200);
    }
    this.toggleTargetElementTransitionClasses(
      this.overlayTarget,
      this.modalVisible,
      this.overlayEnteringClasses,
      this.overlayEnteringFromClasses,
      this.overlayEnteringToClasses,
      this.overlayLeavingClasses,
      this.overlayLeavingFromClasses,
      this.overlayLeavingToClasses,
      200,
    );
    this.toggleTargetElementTransitionClasses(
      this.modalTarget,
      this.modalVisible,
      this.modalEnteringClasses,
      this.modalEnteringFromClasses,
      this.modalEnteringToClasses,
      this.modalLeavingClasses,
      this.modalLeavingFromClasses,
      this.modalLeavingToClasses,
      200,
    );
  }

  private setContent(content: SafeHTMLContent) {
    this.replaceContents(this.modalTarget, this.createContent(content));
  }

  private createContent(content: SafeHTMLContent) {
    const contentContainer = document.createElement("div");
    contentContainer.id = `${this.element.id}-content`;

    // We likely want to allow arbitrary HTML here so that the dialog content can be formatted appropriately, and allow things like other MDC components to be rendered inside.
    // The problem with allowing arbitrary HTML to be rendered is that it opens the possibility of a XSS attack.
    // To try and mitigate this issue, we ask that the developer explicitly wraps the content inside an object with a `__safe` property.
    // Of course, this can't guarantee that a developer is passing safe content, but it at least gets them thinking about the dangers of XSS when pushing content to be displayed.
    // https://www.owasp.org/index.php/Cross-site_Scripting_(XSS)
    safelySetInnerHTML(contentContainer, content);

    return contentContainer;
  }

  private replaceContents(target: HTMLDivElement, content: HTMLDivElement) {
    replaceContentsWithChildren(target, content);
  }

  private async getContent(
    shownWith: ModalShowOptions,
  ): Promise<SafeHTMLContent> {
    this.dispatchLifecycleEvent(ModalEvents.Loading, { shownWith });
    return axios
      .get<string>(shownWith.contentHref!, {
        headers: {
          "Content-Type": "text/html",
        },
      })
      .then((response) => {
        this.dispatchLifecycleEvent(ModalEvents.Loaded, { shownWith });
        return markAsSafeHTML(response.data);
      });
  }

  protected dispatchLifecycleEvent(
    type: string,
    detail?: ModalLifecycleEventDetail,
  ) {
    const evt = new CustomEvent(type, {
      bubbles: true,
      cancelable: false,
      detail: detail,
    });
    console.debug(`Modal: dispatching ${type} event:`, detail);
    window.dispatchEvent(evt);
  }
}
