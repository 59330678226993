import BaseController from "decor/base_controller";

export default class ErrorIconSectionController extends BaseController {
  public static targets = ["errorText"];

  protected declare readonly errorTextTarget: HTMLDivElement;
  protected declare readonly hasErrorTextTarget: boolean;

  public set errorText(errorMessage: string | null) {
    if (!this.hasErrorTextTarget) return;

    if (errorMessage) {
      this.errorTextTarget.textContent = errorMessage;
    }
    this.show = errorMessage != null;
  }

  public set show(show: boolean) {
    this.toggleTargetElementClasses(this.element, show, ["hidden"], []);
  }
}
