import BaseController from "decor/base_controller";

export default abstract class PurchaseConfiguratorVariantAbstract extends BaseController {
  public get id() {
    return this.data.get("variantEncodedId");
  }

  public set isCurrent(isCurrent: boolean) {
    const classToToggle =
      "shopping__purchase-configurator__variant-details--current";
    // IE11 don't support second parameter in classList.toggle(classNmae, condition)
    isCurrent
      ? this.element.classList.add(classToToggle)
      : this.element.classList.remove(classToToggle);
  }
}
