import BaseController from "decor/base_controller";
import { showModal } from "lib/overlay/modal";
import { markAsSafeHTML } from "lib/util/safe_html";

export default class PriceEditController extends BaseController {
  public handleButtonClick(evt: Event) {
    // Stop propagation: prevent any clicks leaking out
    evt.preventDefault();
    evt.stopPropagation();

    // Show the dialog
    const [contentHref, placeholder] = this.getRequiredDataAttrs(
      "contentHref",
      "placeholder",
    );
    showModal({
      contentHref,
      placeholder: markAsSafeHTML(placeholder),
    });
  }
}
