import BaseController from "decor/base_controller";
import { SafeHTMLContent } from "lib/types";
import { localeMessage } from "lib/i18n";
import { markAsSafeHTML, safelySetInnerHTML } from "lib/util/safe_html";
import { replaceContentsWithChildren } from "lib/util/replace_with_dom_nodes";
import axios from "lib/axios";

export default class DropdownController extends BaseController {
  public static targets = ["menu", "button"];

  private declare readonly menuTarget: HTMLDivElement;
  private declare readonly buttonTarget: HTMLButtonElement;
  private declare readonly hasButtonTarget: boolean;

  public static classes = [
    "entering",
    "enteringFrom",
    "enteringTo",
    "leaving",
    "leavingFrom",
    "leavingTo",
  ];

  protected declare readonly enteringClasses: string[];
  protected declare readonly enteringFromClasses: string[];
  protected declare readonly enteringToClasses: string[];
  protected declare readonly leavingClasses: string[];
  protected declare readonly leavingFromClasses: string[];
  protected declare readonly leavingToClasses: string[];

  private shown: boolean = false;

  public toggle(event: Event) {
    if (this.shown) {
      this.hide();
    } else {
      this.show();
    }
  }

  public hideOnClickOutside(event?: Event) {
    if (!this.shown) {
      return;
    }
    // If the menu itself is clicked, we don't want to hide the menu.
    if (event && this.menuTarget.contains(event.target as Node)) {
      return;
    }
    if (
      event &&
      this.hasButtonTarget &&
      this.buttonTarget.contains(event.target as Node)
    ) {
      return;
    }
    this.hide();
  }

  public hide() {
    this.displayMenu(false);
    this.shown = false;
  }

  public show() {
    this.prepareContent();
    this.displayMenu(true);
    this.shown = true;
  }

  private displayMenu(state: boolean) {
    this.toggleTargetElementTransitionClasses(
      this.menuTarget,
      state,
      this.enteringClasses,
      this.enteringFromClasses,
      this.enteringToClasses,
      this.leavingClasses,
      this.leavingFromClasses,
      this.leavingToClasses,
      this.leaveTimeout(),
    );
  }

  private leaveTimeout() {
    const val = this.data.get("leaveTimeout");
    if (val == null) {
      return;
    }
    return parseInt(val, 10);
  }

  protected async prepareContent() {
    const contentHref = this.getOptionalDataAttr("contentHref");
    const placeholder = this.getOptionalDataAttr("placeholder");
    if (placeholder) {
      this.setContent(markAsSafeHTML(placeholder));
    }
    if (contentHref) {
      this.getContent(contentHref)
        .then((c) => {
          this.setContent(c);
        })
        .catch((err) => {
          console.error(
            "Could not fetch content for dropdown",
            contentHref,
            err,
          );
          const errorMessage = localeMessage("generic_server_error");
          this.setContent(markAsSafeHTML(errorMessage));
        });
    }
  }

  private async getContent(contentHref: string): Promise<SafeHTMLContent> {
    return axios
      .get<string>(contentHref, {
        headers: {
          "Content-Type": "text/html",
        },
      })
      .then((response) => {
        return markAsSafeHTML(response.data);
      });
  }

  private setContent(content: SafeHTMLContent) {
    replaceContentsWithChildren(this.menuTarget, this.createContent(content));
  }

  private createContent(content: SafeHTMLContent) {
    const contentContainer = document.createElement("div");
    contentContainer.id = `${this.element.id}-content`;

    // We likely want to allow arbitrary HTML here so that the dialog content can be formatted appropriately, and allow things like other MDC components to be rendered inside.
    // The problem with allowing arbitrary HTML to be rendered is that it opens the possibility of a XSS attack.
    // To try and mitigate this issue, we ask that the developer explicitly wraps the content inside an object with a `__safe` property.
    // Of course, this can't guarantee that a developer is passing safe content, but it at least gets them thinking about the dangers of XSS when pushing content to be displayed.
    // https://www.owasp.org/index.php/Cross-site_Scripting_(XSS)
    safelySetInnerHTML(contentContainer, content);

    return contentContainer;
  }
}
