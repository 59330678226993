import {
  Decor__Forms__ErrorIconSectionControllerIdentifier,
  Decor__Forms__HelperTextSectionControllerIdentifier,
} from "controllers/identifiers";
import BaseController from "decor/base_controller";
import HelperTextSectionController from "./helper_text_section_controller";
import ErrorIconSectionController from "./error_icon_section_controller";

export default class FormFieldLayoutController extends BaseController {
  public static outlets = [
    Decor__Forms__HelperTextSectionControllerIdentifier,
    Decor__Forms__ErrorIconSectionControllerIdentifier,
  ];
  private declare readonly decorFormsHelperTextSectionOutlet: HelperTextSectionController;
  private declare readonly hasDecorFormsHelperTextSectionOutlet: boolean;
  private declare readonly decorFormsErrorIconSectionOutlet: ErrorIconSectionController;
  private declare readonly hasDecorFormsErrorIconSectionOutlet: boolean;

  private get hasHelperTextSection() {
    return this.hasDecorFormsHelperTextSectionOutlet;
  }
  private get helperTextSection() {
    return this.decorFormsHelperTextSectionOutlet;
  }
  private get hasErrorIconSection() {
    return this.hasDecorFormsErrorIconSectionOutlet;
  }
  private get errorIconSection() {
    return this.decorFormsErrorIconSectionOutlet;
  }

  public static targets = ["label"];

  protected declare readonly labelTarget: HTMLDivElement;
  protected declare readonly hasLabelTarget: HTMLDivElement;

  public static classes = ["validLabel", "invalidLabel"];

  protected declare readonly validLabelClasses: string[];
  protected declare readonly invalidLabelClasses: string[];

  public set valid(isValid: boolean) {
    if (this.hasLabelTarget) {
      this.toggleTargetElementClasses(
        this.labelTarget,
        isValid,
        this.invalidLabelClasses,
        this.validLabelClasses,
      );
    }
    if (this.hasHelperTextSection) {
      this.helperTextSection.toggleHelpError = isValid;
    }
    if (this.hasErrorIconSection) {
      this.errorIconSection.show = !isValid;
    }
  }

  public set errorTextContent(errorMessage: string | null) {
    if (this.hasHelperTextSection) {
      this.helperTextSection.errorText = errorMessage;
    }
    if (this.hasErrorIconSection) {
      this.errorIconSection.errorText = errorMessage;
    }
  }
}
