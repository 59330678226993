import { MessageNotificationData } from "lib/types";
import NotificationsController from "./notifications_controller";

export default class NotificationsEmitterController extends NotificationsController {
  public handleNewMessage(data: MessageNotificationData) {
    const { message } = data;
    if (
      message.sender_user_encoded_id !==
        this.data.get("current-user-encoded_id") &&
      message.type === "Message"
    ) {
      this.incrementCounter();
    }

    const event = new CustomEvent("notification", {
      bubbles: true,
      cancelable: false,
      detail: data,
    });
    window.dispatchEvent(event);
  }
}
