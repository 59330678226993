import BaseController from "decor/base_controller";
import { showModal } from "lib/overlay/modal";

export default class RefreshPopupExampleController extends BaseController {
  public showRefreshPopup() {
    showModal({
      contentHref: "/marketplace/reminders/refresh-page",
    });
  }
}
