import { Decor__Forms__CheckboxControllerIdentifier } from "controllers/identifiers";
import CheckboxController from "decor/forms/checkbox_controller";
import FormFieldController from "decor/forms/form_field_controller";

export default class ExpandingCheckboxCollectionController extends FormFieldController {
  public static outlets = [Decor__Forms__CheckboxControllerIdentifier];
  private declare readonly decorFormsCheckboxOutlets: CheckboxController[];
  private get checkboxControllers() {
    return this.decorFormsCheckboxOutlets;
  }

  public static targets = ["showMoreLink"];

  private declare readonly showMoreLinkTarget: HTMLElement;

  private showingMore = false;

  public onInitialize() {
    this.onConnect(() => {
      this.checkboxControllers.forEach((checkbox) => {
        if (
          checkbox.checked &&
          checkbox.element.classList.contains("hideable")
        ) {
          checkbox.element.classList.remove("hidden");
        }
      });
    });

    return super.onInitialize();
  }

  public validate() {
    if (!this.data.get("required")) {
      return {
        errors: [],
        valid: true,
      };
    }
    return {
      errors: [
        {
          code: "missingValue",
          message: this.generateErrorMessage(
            "validationMessageRequired",
            "blank",
          ),
        },
      ],
      valid: !!this.checkboxControllers.find((field) => field.checked),
    };
  }

  public showMore() {
    this.checkboxControllers.forEach((checkbox) => {
      const classList = checkbox.element.classList;
      if (classList.contains("hideable")) {
        if (checkbox.checked) {
          classList.remove("hidden");
        } else {
          classList.toggle("hidden");
        }
      }
    });
    this.showingMore = !this.showingMore;
    this.showMoreLinkTarget.innerText = this.showingMore
      ? "Show less..."
      : "Show more...";
  }
}
