import BaseController from "decor/base_controller";
import { showModal } from "lib/overlay/modal";
import { markAsSafeHTML } from "lib/util/safe_html";

export default class ModalOpenButtonController extends BaseController {
  public handleButtonClick(event: Event) {
    event.preventDefault();

    const href = this.getOptionalDataAttr("contentHref");
    const placeholder = this.getOptionalDataAttr("initialContent");
    const closeOnOverlayClick =
      this.getOptionalDataAttr("closeOnOverlayClick") == "true";
    showModal({
      contentHref: href,
      closeOnOverlayClick,
      placeholder: placeholder ? markAsSafeHTML(placeholder) : undefined,
    });
  }
}
