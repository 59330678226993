import BaseController from "decor/base_controller";

const HIDDEN_CLASS = "invisible";

export default class EntityUserFormController extends BaseController {
  public static targets = [
    "emailField",
    "emailConfirmationField",
    "emailConfirmationContainer",
    "passwordField",
    "passwordConfirmationField",
    "passwordConfirmationContainer",
  ];

  private declare readonly emailFieldTarget: HTMLInputElement;
  private declare readonly emailConfirmationFieldTarget: HTMLInputElement;
  private declare readonly emailConfirmationContainerTarget: HTMLBaseElement;

  private declare readonly passwordFieldTarget: HTMLInputElement;
  private declare readonly hasPasswordFieldTarget: boolean;
  private declare readonly passwordConfirmationFieldTarget: HTMLInputElement;
  private declare readonly passwordConfirmationContainerTarget: HTMLBaseElement;

  public onInitialize() {
    this.handleEmailChange();
    this.handlePasswordChange();

    if (this.data.get("alwaysShowEmailConfirmation")) {
      this.showEmailConfirmation();
    }

    return super.onInitialize();
  }

  public handleEmailChange() {
    if (this.data.get("alwaysShowEmailConfirmation")) {
      return;
    }
    const emailFieldValue = this.emailFieldTarget.value;
    if (emailFieldValue !== this.originalEmail) {
      return this.showEmailConfirmation();
    }
    this.emailConfirmationFieldTarget.required = false;
    this.emailConfirmationContainerTarget.classList.add(HIDDEN_CLASS);
  }

  public handlePasswordChange() {
    if (!this.hasPasswordFieldTarget) {
      return;
    }
    const fieldValue = this.passwordFieldTarget.value;
    if (fieldValue != "") {
      this.passwordConfirmationFieldTarget.required = true;
      return this.passwordConfirmationContainerTarget.classList.remove(
        HIDDEN_CLASS,
      );
    }
    this.passwordConfirmationFieldTarget.required = false;
    this.passwordConfirmationContainerTarget.classList.add(HIDDEN_CLASS);
  }

  private showEmailConfirmation() {
    this.emailConfirmationFieldTarget.required = true;
    return this.emailConfirmationContainerTarget.classList.remove(HIDDEN_CLASS);
  }

  private get originalEmail(): string | null {
    const originalValue = this.data.get("originalEmail");
    if (originalValue === null) {
      return originalValue;
    }
    return originalValue as string;
  }
}
