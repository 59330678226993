import {
  ModalEvents,
  ModalLifecycleEvent,
  ModalLifecycleEventDetail,
  ModalShowOptions,
} from "decor/modal_controller";
import {
  ModalConfirmEvents,
  ModalConfirmOptions,
} from "decor/confirm_modal_controller";

export function showConfirmModal(opts: ModalConfirmOptions) {
  const { onClosing, ...rest } = opts;
  function closingHandler(e: Event) {
    window.removeEventListener(ModalConfirmEvents.Closing, closingHandler);
    if (onClosing) {
      onClosing(e as ModalLifecycleEvent);
    }
  }

  window.addEventListener(ModalConfirmEvents.Closing, closingHandler);

  const evt = new CustomEvent(ModalConfirmEvents.Open, {
    bubbles: true,
    cancelable: false,
    detail: rest,
  });
  window.dispatchEvent(evt);
}

// Methods to show and hide the dialog:
//
//     showModal({
//       contentHref: "/my_html_fragment",
//       placeholder: markAsSafeHTML(`<h1>...</h1><p>...</p>`),
//       onClosing: () => {}
//     })
export function showModal(opts: ModalShowOptions) {
  const { onClosing, ...rest } = opts;
  function closingHandler(e: Event) {
    window.removeEventListener(ModalEvents.Closing, closingHandler);
    if (onClosing) {
      onClosing(e as ModalLifecycleEvent);
    }
  }

  window.addEventListener(ModalEvents.Closing, closingHandler);

  const evt = new CustomEvent(ModalEvents.Open, {
    bubbles: true,
    cancelable: false,
    detail: rest,
  });
  window.dispatchEvent(evt);
}

export function closeModal(opts?: ModalLifecycleEventDetail) {
  const evt = new CustomEvent(ModalEvents.Close, {
    bubbles: true,
    cancelable: false,
    detail: opts,
  });
  window.dispatchEvent(evt);
}
