/**
 * Helpers for ordering notification items by sent_at attribute value.
 */

export default function parseDateTime(html: string): number {
  const sentAtRegex = /datetime="(.*)"\s/g;
  const match = sentAtRegex.exec(html);

  return match ? Number(match[1]) : 0;
}
