interface IValidationMessageVariables {
  [key: string]: string;
}

const RAILS_I18N_MESSAGE_REGEX = /%{([\s\S]+?)}/g;

export function generateFormValidationMessage(
  message: string,
  variables: IValidationMessageVariables = {},
) {
  return message.replace(
    RAILS_I18N_MESSAGE_REGEX,
    (m, $1) => variables[$1] || "",
  );
}
