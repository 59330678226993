import {
  CustomFormSubmissionEvent,
  FormFieldError,
  FormFieldValidationResponse,
  ValidateFormFieldsEvent,
} from "lib/types";

import BaseController from "decor/base_controller";
import FormFieldController from "decor/forms/form_field_controller";
import { localeMessage } from "lib/i18n";

import FormControlController from "decor/forms/form_control_controller";

export default class FormController extends BaseController {
  public onInitialize() {
    this.onConnect(() => {
      // Disable HTML5 validation, we will handle that ourselves.
      this.element.setAttribute("novalidate", "true");
      return () => {
        this.element.removeAttribute("novalidate");
      };
    });

    // Ensure all form controls under this form are auto-validated
    // The control will take care of reversing this when it disconnects
    this.onChildControllerConnect((controller: BaseController) => {
      if (controller instanceof FormControlController) {
        controller.autoValidation = true;
      }
    });

    return super.onInitialize();
  }

  public handleSubmitEvent(evt: Event) {
    const hasInvalidFields = this.performValidation();
    if (hasInvalidFields) {
      // At least one field was invalid.
      // Prevent form submission.
      evt.preventDefault();

      // Ensure that the event doesn't propagate.
      // Without this, if data-disable-with is set on the submit button,
      // the button will be disabled even if the form is invalid.
      evt.stopPropagation();

      // Ensure any other submission handlers on this form element are skipped
      evt.stopImmediatePropagation();
    }
  }

  public handleCustomSubmitEvent(evt: Event) {
    // This event is meant for our eyes only.
    evt.stopPropagation();

    const {
      detail: { onSubmissionPrevented },
    } = evt as CustomFormSubmissionEvent;

    const hasInvalidFields = this.performValidation();
    if (hasInvalidFields) {
      onSubmissionPrevented();
    } else {
      this.formElement.submit();
    }
  }

  public handleValidateFieldsEvent(evt: Event) {
    // This event is meant for our eyes only.
    evt.stopPropagation();

    const {
      detail: { onValidated },
    } = evt as ValidateFormFieldsEvent;

    const hasInvalidFields = this.performValidation();
    onValidated(!hasInvalidFields);
  }

  private get formElement(): HTMLFormElement {
    return this.element as HTMLFormElement;
  }

  private get formFieldControllers(): FormFieldController[] {
    return this.getSpecificChildControllers<FormFieldController>(
      FormFieldController,
    );
  }

  private performValidation(): boolean {
    // Validate all fields.
    const { invalidFields, errorMessages } = this.harvestInvalids();

    const hasInvalidFields = invalidFields.length > 0;

    // Issue an event to say that the entire form was validated.
    // This allows higher controllers the opportunity to display
    // an overall summary of the fields that are in error.
    this.emitValidationEvent({
      errors: errorMessages,
      valid: !hasInvalidFields,
    });

    if (hasInvalidFields) {
      // At least one field was invalid.
      // Focus the first field that is in error.
      invalidFields[0].focusControl();
    }

    return hasInvalidFields;
  }

  private harvestInvalids() {
    let invalidFields: FormFieldController[] = [];
    let errorMessages: FormFieldError[] = [];

    this.formFieldControllers.forEach((fc) => {
      if (fc.disabled) {
        // Don't validate any disabled fields.
        return;
      }
      const { valid, errors } = fc.validate();
      if (!valid) {
        invalidFields = [...invalidFields, fc];
        errorMessages = [...errorMessages, ...errors];
      }
    });

    return {
      errorMessages,
      invalidFields,
    };
  }

  private emitValidationEvent({ errors, valid }: FormFieldValidationResponse) {
    this.dispatch("validated", {
      bubbles: true,
      cancelable: false,
      detail: {
        errors,
        preamble: localeMessage("form_invalid_preamble"),
        valid,
      },
    });
  }
}
