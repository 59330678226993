import {
  NOTIFICATION_MANAGER_CLASS_NAME,
  NotificationDismissOptions,
  NotificationShowOptions,
} from "decor/notification_manager_controller";

export function showNotification(opts: NotificationShowOptions) {
  const evt = new CustomEvent(`${NOTIFICATION_MANAGER_CLASS_NAME}:show`, {
    bubbles: true,
    cancelable: false,
    detail: opts,
  });
  window.dispatchEvent(evt);
}

export function dismissAllNotifications() {
  const evt = new CustomEvent(`${NOTIFICATION_MANAGER_CLASS_NAME}:dismissAll`, {
    bubbles: true,
    cancelable: false,
  });
  window.dispatchEvent(evt);
}

export function dismissSingleNotification(opts: NotificationDismissOptions) {
  const evt = new CustomEvent(`${NOTIFICATION_MANAGER_CLASS_NAME}:dismiss`, {
    bubbles: true,
    cancelable: false,
    detail: opts,
  });
  window.dispatchEvent(evt);
}
