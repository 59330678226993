import FormFieldController from "decor/forms/form_field_controller";

export default class TextFieldController extends FormFieldController {
  public static targets = [
    "leadingTextAddOn",
    "trailingTextAddOn",
  ];

  protected declare readonly leadingTextAddOnTarget: HTMLSpanElement;
  protected declare readonly trailingTextAddOnTarget: HTMLSpanElement;
}
