import { Controller, ControllerConstructor } from "@hotwired/stimulus";

interface ControllerConnectionEvent
  extends CustomEvent<{
    identifier: string;
  }> {
  target: Element;
}
interface BaseControllerConstructor {
  identifier: string;
  new (...args: any[]): BaseController;
}

type DisconnectorFn = () => void;
type ConnectorFn = () => DisconnectorFn | void;
type ChildControllerConnectorFn = (controller: BaseController) => void;

// Intentionally an object so that === will compare against this exact reference.
// We could use a Symbol here, but IE11 will barf and I don't think it merits a full on polyfill.
const initializeReceipt = {};

const CONNECTION_EVENT = "cnf:controller:connected";
const DISCONNECTION_EVENT = "cnf:controller:disconnected";

export default abstract class BaseController extends Controller {
  private connectors: ConnectorFn[] = [];
  private disconnectors: DisconnectorFn[] = [];
  private connectionDisconnectors: DisconnectorFn[] = [];

  private childControllerConnectors: ChildControllerConnectorFn[] = [];
  private childControllerDisconnectors: ChildControllerConnectorFn[] = [];

  private childControllers: Set<BaseController> = new Set();

  public static identifier: string;

  // Set the identifier as a static for the controller
  public static setIdentifier(id: string) {
    this.identifier = id;
  }

  /**
   * These lifecycle methods are provided by Stimulus.
   * We won't use these directly, instead we will call helpers which make it easier and safer to hook in to the controller lifecyle.
   */
  public initialize() {
    const receipt = this.onInitialize();
    if (receipt !== initializeReceipt) {
      // If we get here, it means that a subclass did not call `super.onInitialize()`.
      // This guard therefore ensures that every subclass should call `super.onInitialize()`.
      throw new Error(
        `onInitialize was implemented in ${this.identifier} without a call to super.onInitialize.`,
      );
    }
  }

  public connect() {
    // Run all connectors. If any return disconnectors, cache them for later use.
    this.connectionDisconnectors = this.connectors.reduce(
      this.runConnector,
      [],
    );
  }

  public disconnect() {
    // Run any permanent disconnectors.
    this.disconnectors.forEach(this.runDisconnector);

    // Run any disconnectors that were returned from connectors,
    // and clear the cache ready for the next run.
    this.connectionDisconnectors.forEach(this.runDisconnector);
    this.connectionDisconnectors = [];

    // Clear out any child controllers.
    this.childControllers.clear();
  }

  // Instead of using the lifecycle methods above, we'll use these.

  // This is called by `initialize`.
  public onInitialize() {
    this.onConnect(() => {
      // Listen for a connection event from an element further down in the DOM tree.
      this.element.addEventListener(
        CONNECTION_EVENT,
        this.handleControllerConnectedEvent,
      );
      this.element.addEventListener(
        DISCONNECTION_EVENT,
        this.handleControllerDisconnectedEvent,
      );

      // Remove the local cache when the child disconnects.
      const disconnector = () => {
        this.emitDisconnectionEvent();
        this.element.removeEventListener(
          CONNECTION_EVENT,
          this.handleControllerConnectedEvent,
        );
        this.element.removeEventListener(
          DISCONNECTION_EVENT,
          this.handleControllerDisconnectedEvent,
        );
      };
      this.emitConnectionEvent();
      return disconnector;
    });

    // This receipt is checked by `initialize`.
    // This ensures that any implementing method must call down the prototype chain,
    //  so that initializers are not skipped.
    // https://github.com/Microsoft/TypeScript/issues/21388#issuecomment-360214959
    return initializeReceipt;
  }

  /**
   * Adds a 'connector' function, which is run when the controller is connected.
   * This connector function can optionally return a 'disconnector' function,
   * which will be run when the controller is disconnected.
   * This couples the connect/disconnect lifecycle together for a particular operation.
   * (Inspired in part by the `useEffect` React hook).
   *
   * @param fn a connector function, when run optionally returns a 'disconnector' function
   */
  public onConnect(fn: ConnectorFn) {
    this.connectors.push(fn);
  }

  /**
   * Adds a 'disconnector' function, which is run when the controller is disconnected.
   *
   * @param fn a disconnector function
   */
  public onDisconnect(fn: DisconnectorFn) {
    this.disconnectors.push(fn);
  }

  /**
   * Adds a 'connector' function, which is run when a child controller is connected.
   * A child controller is defined as a controller which connects to a child DOM element.
   *
   * @param fn a connector function
   * @deprecated use Stimulus outlet callbacks instead.
   */
  public onChildControllerConnect(fn: ChildControllerConnectorFn) {
    this.childControllerConnectors.push(fn);
  }

  /**
   * Adds a 'disconnector' function, which is run when a child controller is disconnected.
   * A child controller is defined as a controller which connects to a child DOM element.
   *
   * @param fn a disconnector function
   * @deprecated use Stimulus outlet callbacks instead.
   */
  public onChildControllerDisconnect(fn: ChildControllerConnectorFn) {
    this.childControllerDisconnectors.push(fn);
  }

  /**
   * Finds all child controllers that are instances of the passed class.
   * @param ControllerClass controller class to use to find
   * @deprecated use Stimulus outlets instead
   */
  public getSpecificChildControllers<T extends BaseController>(
    // Need to allow the `| Function` so that abstract classes can be used as the `ControllerClass` argument
    // https://github.com/Microsoft/TypeScript/issues/5843
    // tslint:disable-next-line ban-types
    ControllerClass: ControllerConstructor | Function,
  ): T[] {
    return Array.from(this.childControllers).filter(
      (c) => c instanceof ControllerClass,
    ) as T[];
  }

  /**
   * Finds the first child controller that is an instance of the passed class.
   * @param ControllerClass controller class to use to find
   * @deprecated use Stimulus outlets instead
   */
  public getSpecificChildController<T extends BaseController>(
    // Need to allow the `| Function` so that abstract classes can be used as the `ControllerClass` argument
    // https://github.com/Microsoft/TypeScript/issues/5843
    // tslint:disable-next-line ban-types
    ControllerClass: ControllerConstructor | Function,
  ): T | null {
    const result = Array.from(this.childControllers).find(
      (c) => c instanceof ControllerClass,
    );
    return result ? (result as T) : null;
  }

  public findParentElementByTagName(el: Element, tagName: string) {
    tagName = tagName.toLowerCase();

    while (el && el.parentElement) {
      el = el.parentElement;
      if (el.tagName && el.tagName.toLowerCase() === tagName) {
        return el;
      }
    }
    return null;
  }

  public get disabled() {
    return this.element.hasAttribute("disabled");
  }

  public set disabled(disabled: boolean) {
    if (disabled) {
      this.element.setAttribute("disabled", "disabled");
    } else {
      this.element.removeAttribute("disabled");
    }
  }

  // ---

  protected findController(
    element: Element,
    identifier: string,
  ): BaseController | null {
    // It would be more performant to pass the whole controller through the custom event,
    // so then it doesn't need to be looked up here every time the event is received.
    // I'm not sure how well a class sends via a DOM event, though!
    const controller = this.application.getControllerForElementAndIdentifier(
      element,
      identifier,
    );
    return controller instanceof BaseController ? controller : null;
  }

  public getOptionalDataAttr(key: string): string | undefined {
    const value = this.data.get(key);
    return value === null ? undefined : value;
  }

  public getRequiredDataAttr(key: string): string {
    const value = this.data.get(key);
    if (!value) {
      throw new Error(
        `${key} data attribute must be set for controller ${this.identifier}`,
      );
    }
    return value;
  }

  public getRequiredDataAttrs(...attrs: string[]): string[] {
    const found: string[] = [];
    const missing: string[] = [];

    attrs.forEach((key) => {
      const value = this.data.get(key);
      if (!value) {
        missing.push(key);
      } else {
        found.push(value);
      }
    });

    if (missing.length === 1) {
      throw new Error(
        `${missing[0]} data attribute must be set for controller ${this.identifier}`,
      );
    } else if (missing.length > 1) {
      throw new Error(
        `${missing.join(", ")} data attributes must be set for controller ${
          this.identifier
        }`,
      );
    }

    return found;
  }

  public getOptionalDataAttrAsJSON<T>(key: string): T | undefined {
    const value = this.data.get(key);
    if (value === null) {
      return undefined;
    }
    try {
      return JSON.parse(value);
    } catch (e: any) {
      throw new Error(
        `${key} data attribute for controller ${this.identifier} was not valid JSON: ${e.message}`,
      );
    }
  }

  public getRequiredDataAttrAsJSON<T>(key: string): T {
    const value = this.data.get(key);
    if (!value) {
      throw new Error(
        `${key} data attribute must be set for controller ${this.identifier}`,
      );
    }
    try {
      return JSON.parse(value);
    } catch (e: any) {
      throw new Error(
        `${key} data attribute for controller ${this.identifier} was not valid JSON: ${e.message}`,
      );
    }
  }

  protected toggleTargetElementTransitionClasses(
    target: Element,
    state: boolean,
    classesEntering: string[],
    classesEnteringFrom: string[],
    classesEnteringTo: string[],
    classesLeaving: string[],
    classesLeavingFrom: string[],
    classesLeavingTo: string[],
    hideOnLeaveMs: number | null = null,
    delayToSetToMs: number = 0,
  ) {
    this.toggleTargetElementClasses(
      target,
      state,
      classesLeaving,
      classesEntering,
    );
    this.toggleTargetElementClasses(
      target,
      state,
      classesLeavingFrom,
      classesEnteringFrom,
    );
    if (hideOnLeaveMs) {
      if (state) {
        // console.log("Remove hidden");
        this.setTargetElementClasses(target, ["hidden"], []);
      } else {
        setTimeout(() => {
          // console.log("Add hidden");
          this.setTargetElementClasses(target, [], ["hidden"]);
        }, hideOnLeaveMs);
      }
    }
    // console.log("anim start state", state, target.classList);
    setTimeout(() => {
      // console.log("anim next frame", state, target.classList);
      if (state) {
        this.setTargetElementClasses(
          target,
          classesLeavingFrom.concat(classesLeavingTo),
          [],
        );
        this.toggleTargetElementClasses(
          target,
          state,
          classesEnteringFrom,
          classesEnteringTo,
        );
      } else {
        this.setTargetElementClasses(
          target,
          classesEnteringFrom.concat(classesEnteringTo),
          [],
        );
        this.toggleTargetElementClasses(
          target,
          true,
          classesLeavingFrom,
          classesLeavingTo,
        );
      }
      // console.log("anim next frame after", target.classList);
    }, delayToSetToMs);
  }

  protected toggleTargetElementClasses(
    target: Element,
    state: boolean,
    classesOff: string[],
    classesOn: string[],
  ) {
    if (state) {
      this.setTargetElementClasses(target, classesOff, classesOn);
    } else {
      this.setTargetElementClasses(target, classesOn, classesOff);
    }
  }

  protected setTargetElementClasses(
    target: Element,
    classesToRemove: string[],
    classesToAdd: string[],
  ) {
    classesToRemove.forEach((className) => target.classList.remove(className));
    classesToAdd.forEach((className) => target.classList.add(className));
  }

  protected toggleOnSearch(
    search: string,
    target: Element,
    searchTargets: HTMLElement[],
    classesOnMatch: string[],
    classesOnNoMatch: string[],
  ): boolean {
    const st = search.trim().toLowerCase();
    if (st.length < 2 || st == "") {
      this.setTargetElementClasses(target, classesOnNoMatch, classesOnMatch);
      return true;
    }
    const matches =
      searchTargets.filter((searchTarget) =>
        searchTarget.innerText.toLowerCase().includes(st),
      ).length > 0;
    this.toggleTargetElementClasses(
      target,
      matches,
      classesOnNoMatch,
      classesOnMatch,
    );
    return matches;
  }

  // Emit an event with a prefix generated by asking the given controller for
  // its event prefix.
  protected emitEvent(
    target: EventTarget,
    ControllerClass: BaseControllerConstructor,
    eventName: string,
    detail: any = undefined,
    bubbles = true,
    cancelable = false,
  ) {
    console.debug(
      `[${this.identifier}] Emitting event ${ControllerClass.identifier}:${eventName}`,
    );
    this.dispatch(eventName, {
      target: target as Element,
      detail,
      prefix: ControllerClass.identifier,
      bubbles,
      cancelable,
    });
  }

  // ---

  private emitConnectionEvent = () => {
    const evt = new CustomEvent(CONNECTION_EVENT, {
      bubbles: true,
      cancelable: false,
      detail: {
        identifier: this.identifier,
      },
    });
    this.element.dispatchEvent(evt);
  };

  private emitDisconnectionEvent = () => {
    const evt = new CustomEvent(DISCONNECTION_EVENT, {
      bubbles: true,
      cancelable: false,
      detail: {
        identifier: this.identifier,
      },
    });
    this.element.dispatchEvent(evt);
  };

  private handleControllerConnectedEvent = (evt: Event) => {
    const {
      target,
      detail: { identifier },
    } = evt as ControllerConnectionEvent;
    const controller = this.findController(target, identifier);
    if (controller && controller !== this) {
      console.debug(
        `> [${this.identifier}] Controller connected:`,
        controller.identifier,
      );
      this.childControllers.add(controller);
      this.childControllerConnectors.forEach((cb) => cb(controller));
    }
  };

  private handleControllerDisconnectedEvent = (evt: Event) => {
    const {
      target,
      detail: { identifier },
    } = evt as ControllerConnectionEvent;
    const controller = this.findController(target, identifier);
    if (controller && controller !== this) {
      console.debug(
        `> [${this.identifier}] Controller disconnected:`,
        controller.identifier,
      );
      this.childControllers.delete(controller);
      this.childControllerDisconnectors.forEach((cb) => cb(controller));
    }
  };

  private runConnector = (
    disconnectors: DisconnectorFn[],
    connector: ConnectorFn,
  ) => {
    const d = connector();
    if (typeof d === "function") {
      disconnectors.push(d);
    }
    return disconnectors;
  };

  private runDisconnector = (d: DisconnectorFn) => {
    d();
  };
}
