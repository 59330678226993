import BaseController from "decor/base_controller";
// Import only core, import { Swiper } from "swiper"; seems to still bring
// into bundle all of swiper, not just Swiper core
// @ts-ignore
import Swiper from "swiper/core/core.js";

export default class CarouselController extends BaseController {
  private carousel?: Swiper = undefined;

  public onInitialize() {
    this.onConnect(() => {
      this.element.classList.remove("hidden");
      const perView = window.innerWidth > 600 ? 3 : 1.25;
      const desired = this.data.get("slidesPerView");
      this.carousel = new Swiper(this.element as HTMLElement, {
        slidesPerView: desired
          ? parseFloat(this.data.get("slidesPerView")!)
          : perView,
        centeredSlides: true,
        spaceBetween: 16,
      });
    });

    return super.onInitialize();
  }

  public previous() {
    this.carousel!.slidePrev();
  }

  public next() {
    this.carousel!.slideNext();
  }
}
