import BaseController from "decor/base_controller";
import { closeModal } from "lib/overlay/modal";

export default class ModalCloseButtonController extends BaseController {
  public handleButtonClick(event: Event) {
    event.preventDefault();
    const reason = this.data.get("closeReason");
    closeModal({
      closeReason: reason ? reason : undefined,
    });
  }
}
