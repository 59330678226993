import FormFieldController from "decor/forms/form_field_controller";
import { showConfirmModal } from "lib/overlay/modal";
import { markAsSafeHTML } from "lib/util/safe_html";
import { ModalLifecycleEvent } from "decor/modal_controller";
import FormController from "decor/forms/form_controller";

export default class SwitchController extends FormFieldController {
  public static targets = FormFieldController.targets.concat([
    "switch",
    "slider",
    "sliderStart",
    "sliderEnd",
    "checkbox",
  ]);

  protected declare readonly switchTarget: HTMLButtonElement;
  protected declare readonly sliderTarget: HTMLSpanElement;
  protected declare readonly sliderStartTarget: HTMLSpanElement;
  protected declare readonly sliderEndTarget: HTMLSpanElement;
  protected declare readonly checkboxTarget: HTMLInputElement;

  public static classes = [
    "switchOn",
    "switchOff",
    "sliderOn",
    "sliderOff",
    "sliderStartOn",
    "sliderStartOff",
    "sliderEndOff",
    "sliderEndOn",
  ];

  protected declare readonly sliderEndOffClasses: string[];
  protected declare readonly sliderEndOnClasses: string[];
  protected declare readonly sliderStartOffClasses: string[];
  protected declare readonly sliderStartOnClasses: string[];
  protected declare readonly sliderOffClasses: string[];
  protected declare readonly sliderOnClasses: string[];
  protected declare readonly switchOffClasses: string[];
  protected declare readonly switchOnClasses: string[];

  public handleClick(event: Event) {
    event.preventDefault();
    if (!this.checkboxTarget.disabled) {
      this.toggleSwitch();
    }
  }

  public handleChange() {
    if (this.data.has("submitOnChange")) {
      if (!this.data.has("confirmOnSubmit")) {
        // We can submit this right away.
        this.submitForm();
        return;
      }
      // Check if the form is valid - if it is, pop open a confirmation dialog.
      this.dispatch("validate", {
        prefix: FormController.identifier,
        target: this.element,
        bubbles: true,
        cancelable: false,
        detail: {
          onValidated: this.handleFormValidatedBeforeConfirm,
        },
      });
    }
  }

  private handleFormValidatedBeforeConfirm = (isFormValid: boolean) => {
    if (isFormValid) {
      // Show the confirmation dialog, using the text sent in via data attributes.
      const confirmMessage = this.getRequiredDataAttr("confirmOnSubmit");

      // TODO i18n
      const confirmLabel = this.data.get("confirmOnSubmitYes") || "Confirm";
      const cancelLabel = this.data.get("confirmOnSubmitNo") || "Cancel";

      showConfirmModal({
        defaultReason: "cancel",
        negativeButtonReason: "cancel",
        negativeButtonLabel: cancelLabel,
        positiveButtonReason: "confirm",
        positiveButtonLabel: confirmLabel,
        message: confirmMessage,
        onClosing: this.handleDialogClosing,
      });
    } else {
      this.toggleSwitchWithoutChangeEvent();
    }
  };

  private handleDialogClosing = ({
    detail: { closeReason },
  }: ModalLifecycleEvent) => {
    if (closeReason === "confirm") {
      // Submit the form.
      this.submitForm();
    } else {
      this.toggleSwitchWithoutChangeEvent();
    }
  };

  private submitForm = () => {
    // Ask the parent controller to submit the form.
    this.dispatch("submit", {
      prefix: FormController.identifier,
      target: this.element,
      bubbles: true,
      cancelable: false,
      detail: {
        onSubmissionPrevented: this.toggleSwitchWithoutChangeEvent,
      },
    });
  };

  public toggleSwitch = () => {
    this.toggleSwitchState();
    this.toggleSwitchView();
  };

  public toggleSwitchWithoutChangeEvent = () => {
    this.toggleSwitchState(false);
    this.toggleSwitchView();
  };

  private toggleSwitchState(withChangeEvent = true) {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;

    this.validate();
    if (withChangeEvent) this.handleChange();
  }

  private toggleSwitchView() {
    const state = this.checkboxTarget.checked;
    this.toggleTargetElementClasses(
      this.switchTarget,
      state,
      this.switchOffClasses,
      this.switchOnClasses,
    );
    this.toggleTargetElementClasses(
      this.sliderTarget,
      state,
      this.sliderOffClasses,
      this.sliderOnClasses,
    );
    this.toggleTargetElementClasses(
      this.sliderStartTarget,
      state,
      this.sliderStartOffClasses,
      this.sliderStartOnClasses,
    );

    this.toggleTargetElementClasses(
      this.sliderEndTarget,
      state,
      this.sliderEndOffClasses,
      this.sliderEndOnClasses,
    );
  }
}
