import FormFieldController from "decor/forms/form_field_controller";

export default class FileUploadController extends FormFieldController {
  public static classes = ["image"];

  private declare readonly imageClasses: string[];

  public fileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      if (input.files[0].size / 1024 / 1024 > this.maxSize) {
        alert("Sorry that file is too large");
        input.value = "";
      }
      // If an image type, then show preview
      const container = this.element.getElementsByClassName(
        "decor--image-upload--image-container",
      )[0];
      if (container) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const image = document.createElement("img") as HTMLImageElement;
          image.classList.add(...this.imageClasses);
          if (e.target && e.target.result) {
            image.src = e.target.result.toString();
            container.replaceChild(image, container.children[0]);
          }
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }

  private get maxSize() {
    return parseInt(this.getRequiredDataAttr("maxSizeInMb"), 10);
  }
}
