import { AjaxResponseEvent } from "lib/types";

import PurchaseConfiguratorVariantAbstract from "mercor/purchase_configurator/purchase_configurator_variant_abstract";
import BaseController from "decor/base_controller";
import { localeMessage } from "lib/i18n";
import { closeModal } from "lib/overlay/modal";
import { showNotification } from "lib/overlay/notification";
import { markAsSafeHTML } from "lib/util/safe_html";
import ShoppingCartDropdownController from "mercor/shopping_cart_dropdown_controller";
import {
  Mercor__PurchaseConfigurator__ExtraInfoControllerIdentifier,
  Mercor__PurchaseConfigurator__QuantityCalculatorControllerIdentifier,
  Mercor__PurchaseConfigurator__UomPricingDetailsControllerIdentifier,
} from "controllers/identifiers";

export default class PurchaseConfiguratorController extends BaseController {
  public static outlets = [
    Mercor__PurchaseConfigurator__ExtraInfoControllerIdentifier,
    Mercor__PurchaseConfigurator__QuantityCalculatorControllerIdentifier,
    Mercor__PurchaseConfigurator__UomPricingDetailsControllerIdentifier,
  ];

  private declare readonly mercorPurchaseConfiguratorExtraInfoOutlets: PurchaseConfiguratorVariantAbstract[];
  private declare readonly mercorPurchaseConfiguratorQuantityCalculatorOutlets: PurchaseConfiguratorVariantAbstract[];
  private declare readonly mercorPurchaseConfiguratorUomPricingDetailsOutlets: PurchaseConfiguratorVariantAbstract[];

  private get variantControllers() {
    return [
      ...this.mercorPurchaseConfiguratorExtraInfoOutlets,
      ...this.mercorPurchaseConfiguratorQuantityCalculatorOutlets,
      ...this.mercorPurchaseConfiguratorUomPricingDetailsOutlets,
    ];
  }

  public static targets = ["form"];
  // private declare readonly formTarget: HTMLFormElement;

  public onInitialize() {
    // Ensure that all fields except for the chosen variant are initialized as disabled.
    // TODO we can remove this hack once Rails has support for not serializing form inputs inside disabled fieldsets
    // https://github.com/rails/rails/pull/36764
    this.onConnect(() => {
      const chosenVariant = this.getRequiredDataAttr("chosenVariant");
      this.variantControllers.forEach((vc) => {
        vc.isCurrent = vc.id === chosenVariant;
      });
    });

    return super.onInitialize();
  }

  public handleVariantChanged(evt: Event) {
    const { value: chosenVariantId } = evt.target as HTMLInputElement;
    this.chosenVariant = chosenVariantId;
  }

  public handleCancelButton(evt: Event) {
    evt.preventDefault();
    // trigger event which parent should listen for
    closeModal();
  }

  public formSubmitSuccess(evt: AjaxResponseEvent) {
    const [data, status, xhr] = evt.detail;
    closeModal();

    this.emitEvent(window, ShoppingCartDropdownController, "update-total", {
      basePath: xhr.responseURL,
    });

    showNotification({
      content: markAsSafeHTML(xhr.response),
    });
  }

  public formSubmitError(evt: AjaxResponseEvent) {
    const [data, status, xhr] = evt.detail;
    // TODO show this in a flash container somewhere
    try {
      const response = JSON.parse(xhr.response);
      console.error("Failed to submit the purcon form", response);
      showNotification({
        content: markAsSafeHTML(
          response.message || localeMessage("generic_server_error"),
        ),
        timeout: 5000,
      });
    } catch (e: any) {
      throw new Error(
        `Could not parse form Submit response (${this.identifier}), was not valid JSON: ${xhr.response} (status ${status})`,
      );
    }
  }

  private set chosenVariant(chosenVariantId: string) {
    this.variantControllers.forEach((vc) => {
      vc.isCurrent = vc.id === chosenVariantId;
    });
  }
}
