export enum Keys {
  Enter,
}

export function keyWasPressed(evt: KeyboardEvent, key: Keys): boolean {
  switch (key) {
    case Keys.Enter:
      return evt.key === "Enter" || evt.keyCode === 13;
    default:
      return false;
  }
}
