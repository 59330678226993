import { Navigo__SideNavbarSectionControllerIdentifier } from "controllers/identifiers";
import BaseController from "decor/base_controller";
import SideNavbarSectionController from "navigo/side_navbar_section_controller";

const HOVER_BODY_CLASS = "side-navbar-collapsed-hover-open";
const COLLAPSED_BODY_CLASS = "side-navbar-collapsed";

export const SIDE_NAVBAR_MOBILE_TOGGLE_EVENT =
  "navigo--side-navbar:toggle-mobile-overlay";

export default class SideNavbarController extends BaseController {
  public static outlets = [Navigo__SideNavbarSectionControllerIdentifier];
  private declare readonly navigoSideNavbarSectionOutlets: SideNavbarSectionController[];
  private get sectionControllers() {
    return this.navigoSideNavbarSectionOutlets;
  }

  public static targets = [
    "searchNavigation",
    "mobileSearchNavigation",
    "desktopMenu",
    "mobileMenu",
    "mobileMenuOverlay",
    "mobileMenuCanvas",
    "mobileMenuCloseButton",
    "desktopCollapseIcon",
    "desktopExpandIcon",
    "desktopLogo",
    "desktopAvatarLogo",
  ];

  private declare readonly searchNavigationTarget: HTMLInputElement;
  private declare readonly mobileSearchNavigationTarget: HTMLInputElement;
  private declare readonly desktopMenuTarget: HTMLDivElement;
  private declare readonly mobileMenuTarget: HTMLDivElement;
  private declare readonly mobileMenuOverlayTarget: HTMLDivElement;
  private declare readonly mobileMenuCanvasTarget: HTMLDivElement;
  private declare readonly mobileMenuCloseButtonTarget: HTMLButtonElement;
  private declare readonly desktopCollapseIconTarget: HTMLDivElement;
  private declare readonly desktopExpandIconTarget: HTMLDivElement;
  private declare readonly desktopLogoTarget: HTMLDivElement;
  private declare readonly desktopAvatarLogoTarget: HTMLDivElement;

  public static classes = [
    "mobileMenuOverlayEntering",
    "mobileMenuOverlayEnteringFrom",
    "mobileMenuOverlayEnteringTo",
    "mobileMenuOverlayLeaving",
    "mobileMenuOverlayLeavingFrom",
    "mobileMenuOverlayLeavingTo",
    "mobileMenuCanvasEntering",
    "mobileMenuCanvasEnteringFrom",
    "mobileMenuCanvasEnteringTo",
    "mobileMenuCanvasLeaving",
    "mobileMenuCanvasLeavingFrom",
    "mobileMenuCanvasLeavingTo",
    "mobileMenuCloseButtonEntering",
    "mobileMenuCloseButtonEnteringFrom",
    "mobileMenuCloseButtonEnteringTo",
    "mobileMenuCloseButtonLeaving",
    "mobileMenuCloseButtonLeavingFrom",
    "mobileMenuCloseButtonLeavingTo",
  ];
  protected declare readonly mobileMenuOverlayEnteringClasses: string[];
  protected declare readonly mobileMenuOverlayEnteringFromClasses: string[];
  protected declare readonly mobileMenuOverlayEnteringToClasses: string[];
  protected declare readonly mobileMenuOverlayLeavingClasses: string[];
  protected declare readonly mobileMenuOverlayLeavingFromClasses: string[];
  protected declare readonly mobileMenuOverlayLeavingToClasses: string[];
  protected declare readonly mobileMenuCanvasEnteringClasses: string[];
  protected declare readonly mobileMenuCanvasEnteringFromClasses: string[];
  protected declare readonly mobileMenuCanvasEnteringToClasses: string[];
  protected declare readonly mobileMenuCanvasLeavingClasses: string[];
  protected declare readonly mobileMenuCanvasLeavingFromClasses: string[];
  protected declare readonly mobileMenuCanvasLeavingToClasses: string[];
  protected declare readonly mobileMenuCloseButtonEnteringClasses: string[];
  protected declare readonly mobileMenuCloseButtonEnteringFromClasses: string[];
  protected declare readonly mobileMenuCloseButtonEnteringToClasses: string[];
  protected declare readonly mobileMenuCloseButtonLeavingClasses: string[];
  protected declare readonly mobileMenuCloseButtonLeavingFromClasses: string[];
  protected declare readonly mobileMenuCloseButtonLeavingToClasses: string[];

  private debounceSearch?: any = undefined;
  private debounceMouseOver?: any = undefined;
  private collapsed: boolean = false;
  private overlayOpen: boolean = false;

  public onInitialize() {
    this.onConnect(() => {
      if (this.data.get("collapsed") == "true") {
        this.toggleCollapseDesktopMenu();
      }
    });

    return super.onInitialize();
  }

  public toggleMobileMenu() {
    // todo toggle the mobile view
    this.overlayOpen = !this.overlayOpen;
    this.toggleMobileOverlay(this.overlayOpen);
    // Clear search
    this.searchNavigationTarget.value = "";
    this.mobileSearchNavigationTarget.value = "";
    this.search();
  }

  public handleMouseOver() {
    if (this.documentBody.classList.contains(COLLAPSED_BODY_CLASS)) {
      if (this.debounceMouseOver) {
        clearTimeout(this.debounceMouseOver);
      }
      this.debounceMouseOver = setTimeout(() => {
        this.debounceMouseOver = undefined;

        this.toggleLogo(false);
        this.documentBody.classList.add(HOVER_BODY_CLASS);
      }, 500);
    }
  }

  public handleMouseAway() {
    if (this.debounceMouseOver) {
      clearTimeout(this.debounceMouseOver);
    }
    if (this.documentBody.classList.contains(COLLAPSED_BODY_CLASS)) {
      this.toggleLogo(true);
      this.documentBody.classList.remove(HOVER_BODY_CLASS);
    }
  }

  public search() {
    const val =
      this.searchNavigationTarget.value.trim().toLowerCase() ||
      this.mobileSearchNavigationTarget.value.trim().toLowerCase();

    if (this.debounceSearch) {
      clearTimeout(this.debounceSearch);
    }
    this.debounceSearch = setTimeout(() => {
      this.debounceSearch = undefined;
      this.sectionControllers.forEach((section) => {
        section.filterOnSearch(val);
      });
    }, 300);
  }

  public toggleCollapseDesktopMenu() {
    this.collapsed = !this.collapsed;
    this.documentBody.classList.remove(HOVER_BODY_CLASS);
    if (this.collapsed) {
      this.documentBody.classList.add(COLLAPSED_BODY_CLASS);
    } else {
      this.documentBody.classList.remove(COLLAPSED_BODY_CLASS);
    }
    this.toggleLogo(this.collapsed);
    this.toggleTargetElementClasses(
      this.desktopCollapseIconTarget,
      this.collapsed,
      [],
      ["hidden"],
    );
    this.toggleTargetElementClasses(
      this.desktopExpandIconTarget,
      this.collapsed,
      ["hidden"],
      [],
    );
  }

  private toggleMobileOverlay(open: boolean) {
    this.desktopMenuTarget.classList.add("hidden");
    if (open) {
      this.toggleTargetElementClasses(
        this.mobileMenuTarget,
        open,
        ["hidden"],
        [],
      );
    } else {
      setTimeout(() => {
        this.toggleTargetElementClasses(
          this.mobileMenuTarget,
          open,
          ["hidden"],
          [],
        );
      }, 300);
    }
    this.toggleTargetElementTransitionClasses(
      this.mobileMenuOverlayTarget,
      open,
      this.mobileMenuOverlayEnteringClasses,
      this.mobileMenuOverlayEnteringFromClasses,
      this.mobileMenuOverlayEnteringToClasses,
      this.mobileMenuOverlayLeavingClasses,
      this.mobileMenuOverlayLeavingFromClasses,
      this.mobileMenuOverlayLeavingToClasses,
    );
    this.toggleTargetElementTransitionClasses(
      this.mobileMenuCanvasTarget,
      open,
      this.mobileMenuCanvasEnteringClasses,
      this.mobileMenuCanvasEnteringFromClasses,
      this.mobileMenuCanvasEnteringToClasses,
      this.mobileMenuCanvasLeavingClasses,
      this.mobileMenuCanvasLeavingFromClasses,
      this.mobileMenuCanvasLeavingToClasses,
    );
    this.toggleTargetElementTransitionClasses(
      this.mobileMenuCloseButtonTarget,
      open,
      this.mobileMenuCloseButtonEnteringClasses,
      this.mobileMenuCloseButtonEnteringFromClasses,
      this.mobileMenuCloseButtonEnteringToClasses,
      this.mobileMenuCloseButtonLeavingClasses,
      this.mobileMenuCloseButtonLeavingFromClasses,
      this.mobileMenuCloseButtonLeavingToClasses,
    );
  }

  private toggleLogo(collapsed: boolean) {
    this.toggleTargetElementClasses(
      this.desktopLogoTarget,
      collapsed,
      [],
      ["hidden"],
    );
    this.toggleTargetElementClasses(
      this.desktopAvatarLogoTarget,
      collapsed,
      ["hidden"],
      [],
    );
  }

  private get documentBody() {
    return document.getElementsByTagName("body")[0];
  }
}
