import {
  Decor__Forms__CheckboxControllerIdentifier,
  Decor__Tables__DataTableCellControllerIdentifier,
} from "controllers/identifiers";
import BaseController from "decor/base_controller";
import CheckboxController from "decor/forms/checkbox_controller";
import DataTableCellController from "decor/tables/data_table_cell_controller";

export default class DataTableRowController extends BaseController {
  public static targets = ["checkbox"];
  public declare readonly checkboxTarget: HTMLInputElement;

  public static classes = ["selected"];
  protected declare readonly selectedClasses: string[];

  public static outlets = [
    Decor__Tables__DataTableCellControllerIdentifier,
    Decor__Forms__CheckboxControllerIdentifier,
  ];
  private declare readonly decorTablesDataTableCellOutlets: DataTableCellController[];
  private get cellControllers() {
    return this.decorTablesDataTableCellOutlets;
  }
  private declare readonly decorFormsCheckboxOutlet: CheckboxController;
  private get checkboxController() {
    return this.decorFormsCheckboxOutlet;
  }

  public get id(): string {
    return this.getRequiredDataAttr("id");
  }

  public get disabled() {
    return this.element.classList.contains("opacity-50");
  }

  public set disabled(disabled: boolean) {
    if (disabled) {
      this.element.classList.add("opacity-50");
    } else {
      this.element.classList.remove("opacity-50");
    }
  }

  public rowSelected() {
    this.toggleTargetElementClasses(
      this.element,
      this.checked,
      [],
      this.selectedClasses,
    );
  }

  public get checked() {
    if (this.checkboxController) {
      return this.checkboxController.checked;
    }
    return false;
  }

  public set checked(state: boolean) {
    if (this.checkboxController && !this.checkboxController.disabled) {
      this.checkboxController.checked = state;
      this.rowSelected();
    }
  }

  public handleRowClick(e: MouseEvent) {
    const target = e.target;
    if (target) {
      const row = this.element;
      let child = target as HTMLElement | null;
      while (child && child !== row) {
        const cell = this.cellControllers.find((c) => c.element === child);
        if (cell && cell.data.get("no-path-navigation")) {
          return;
        }
        child = child.parentElement;
      }
    }
    const href = this.data.get("href");
    if (href) {
      window.location.href = href;
    }
  }
}
