import BaseController from "decor/base_controller";

export default class extends BaseController {
  static targets: string[] = ["button"];
  buttonTarget!: HTMLButtonElement;

  connect() {
    if (this.formElementsOnPage && this.devEnvironment) {
      this.addComponent();
    }
  }

  analysePage(admin: boolean = false) {
    if (this.formElementsOnPage) {
      if (admin) {
        this.fillFormForAdmin();
      } else {
        this.fillForm();
      }
    } else {
      this.buttonTarget.innerText = "Too many forms on this page…";
    }
  }

  analysePageWithKeybinding(event: KeyboardEvent) {
    if (event.key === "f") {
      this.analysePage();
    } else if (event.key === "a") {
      this.analysePage(true);
    }
  }

  private get formElementsOnPage() {
    return this.numberOfFormElements > 0;
  }

  private get devEnvironment() {
    return process.env.NODE_ENV === "development";
  }

  private addComponent() {
    this.element.insertAdjacentHTML("beforeend", this.buttonTag);
  }

  private get numberOfFormElements() {
    return [].filter.call(
      document.getElementsByTagName("form"),
      (el: HTMLFormElement) => {
        const action = el.getAttribute("action");
        return action && action.startsWith("/account/");
      },
    ).length;
  }

  private get buttonTag(): string {
    return `
      <button
      class="fixed bottom-0 left-0 rounded bg-black py-1 px-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        data-development--fill-form-target="button"
        data-action="util--dev-form-fill#analysePage keydown@window->util--dev-form-fill#analysePageWithKeybinding"
      >
        Fill form
      </button>
    `;
  }

  private fillForm() {
    const forms = document.getElementsByTagName("form");

    [].forEach.call(forms, (form) => {
      this.fillInputsFor(form);
    });
  }

  private fillFormForAdmin() {
    const forms = document.getElementsByTagName("form");

    [].forEach.call(forms, (form) => {
      this.fillInputsFor(form, true);
    });
  }

  private fillInputsFor(form: HTMLFormElement, admin: boolean = false) {
    const inputs = form.getElementsByTagName("input");

    [].forEach.call(inputs, (input: HTMLInputElement) => {
      let regex = /(^.*\[|\].*$)/g;
      let inputName = input.name.replace(regex, "");

      if (input.value.length > 0) {
        return;
      }

      switch (inputName) {
        case "name":
          input.value = this.randomiseValue(this.nameValueOptions());
          break;
        case "email":
          input.value = this.randomiseValue(this.emailValueOptions(admin));
          break;
        case "password":
          input.value = this.randomiseValue(this.passwordValueOptions(admin));
          break;
      }
    });
  }

  private nameValueOptions() {
    return ["Chris", "Kate", "Cameron"];
  }

  private emailValueOptions(admin: boolean) {
    if (admin) {
      return ["super_admin@confinus.com"];
    }
    return ["martha.archer@confinus.com"];
  }

  private passwordValueOptions(admin: boolean) {
    if (admin) {
      return ["password"];
    }
    return ["password"];
  }

  private randomiseValue(items: string[]) {
    return items[Math.floor(Math.random() * items.length)];
  }
}
