import ProductNavigationTierController from "./product_navigation_tier_controller";
import BaseController from "decor/base_controller";
import { stringifyEscapeQuotes } from "lib/util/stringify_escape_quotes";
import trackHover from "lib/util/track_hover";
import { ControllerWithItems, NavigationCategory } from "lib/types";
import { Mercor__ProductNavigationTierControllerIdentifier } from "controllers/identifiers";

const supportsTouch =
  "ontouchstart" in window || (navigator as any).msMaxTouchPoints;

export default class ProductNavigationController extends BaseController {
  public static targets = [
    "dropdown",
    "backdrop",
    "navigation",
    "mobileButton",
    "primaryCategoryList",
  ];

  private declare readonly dropdownTarget: HTMLElement;
  private declare readonly backdropTarget: HTMLElement;
  private declare readonly navigationTarget: HTMLElement;
  private declare readonly mobileButtonTarget: HTMLElement;
  private declare readonly primaryCategoryListTarget: HTMLElement;

  public static outlets = [Mercor__ProductNavigationTierControllerIdentifier];
  private declare readonly mercorProductNavigationTierOutlets: ProductNavigationTierController[];
  private get tierControllers() {
    return this.mercorProductNavigationTierOutlets;
  }

  public items!: NavigationCategory[];

  private currentPath: {
    target?: HTMLBaseElement;
    tier: number;
    key: string;
  }[] = [];

  private startTouchX: number = 0;
  private startTouchY: number = 0;

  public onInitialize() {
    this.items = this.parseItems();
    return super.onInitialize();
  }

  public fingerDown(evt: TouchEvent) {
    // Store current scroll position and then when finger is lifted, we determine
    // if a scroll happened, and thus do not allow the tier to change
    [this.startTouchX, this.startTouchY] = this.getTouchCoords(evt);
  }

  // https://stackoverflow.com/questions/41993176/determine-touch-position-on-tablets-with-javascript/61732450#61732450
  private getTouchCoords(evt: TouchEvent) {
    const actualEvent =
      typeof (evt as any).originalEvent === "undefined"
        ? evt
        : (evt as any).originalEvent;
    var touch = actualEvent.touches[0] || actualEvent.changedTouches[0];
    return [touch.pageX, touch.pageY];
  }

  public tierSelected(e: Event, tier = 0) {
    const target = e.target as HTMLBaseElement;
    const link = target.closest("a");
    if (!link) {
      return;
    }
    const key = this.parseKeyAttribute(link);
    const same =
      this.currentPath.length > 0 &&
      this.currentPath[this.currentPath.length - 1].tier === tier;
    if (!same) {
      this.currentPath.push({ target: link, tier, key });
    }

    if (e.type === "mouseover" && !supportsTouch) {
      trackHover("mouseleave", link, () => {
        this.selectCategory(tier, key);
        this.itemSelected(tier, link);
      });
    } else if (e.type === "touchend" && same && !this.isMobile) {
      // If on a tablet device when you tap on the parent category again it will close, however tapping on any other
      // category a second time, then don't prevent default and navigate to that page
      if (tier === 0) {
        e.preventDefault();
        this.closeNavigation();
      }
    } else if (e.type === "touchend") {
      const [clientX, clientY] = this.getTouchCoords(e as TouchEvent);
      if (
        Math.abs(clientX - this.startTouchX) > 15 ||
        Math.abs(clientY - this.startTouchY) > 15
      ) {
        // User is dragging/scrolling, don't navigate
        e.preventDefault();
        return;
      }
      if (this.selectCategory(tier, key).length > 0) {
        // This is what happens on a mobile device
        e.preventDefault();
        this.itemSelected(tier, target);
      }
    }
  }

  public appendTier(items: NavigationCategory[], parent: NavigationCategory) {
    const tier = this.tierControllers.length + 1;
    if (!items.length) {
      return;
    }
    const htmlClass =
      !this.isMobile && this.isLastTier(items.slice(0, 9))
        ? "last-tier"
        : "regular-tier border-r border-secondary-50";
    const tierHtml = `
      <ul class="${htmlClass} block bg-white w-full pl-2 my-4 max-h-[500px] overflow-auto row-start-1 col-start-1 lg:mt-4 lg:mb-2 lg:h-[356px] lg:w-[224px] divide-y lg:divide-y-0"
           data-controller="mercor--product-navigation-tier"
           data-mercor--product-navigation-tier-tier=${tier}
           data-mercor--product-navigation-tier-items='${stringifyEscapeQuotes(
             items,
           )}'
           data-mercor--product-navigation-tier-parent-category='${stringifyEscapeQuotes(
             parent,
           )}'
           data-action="touchstart->mercor--product-navigation#fingerDown">
      </ul>
    `;
    this.dropdownTarget.insertAdjacentHTML("beforeend", tierHtml);
  }

  public get isMobile() {
    return window.innerWidth < 840;
  }

  public backMenu(e: Event) {
    if (this.currentPath.length == 1) {
      this.closeNavigation();
    } else if (this.currentPath.length > 1) {
      this.currentPath.pop();
      const previous = this.currentPath[this.currentPath.length - 1];
      if (previous!.tier === -1) {
        this.showDropdown();
      }
      this.selectCategory(previous!.tier, previous!.key);
    }
  }

  public showMenu(e: Event) {
    if (this.currentPath.length > 0) {
      this.closeNavigation();
    } else {
      this.currentPath.push({ tier: -1, key: "" });
      this.showDropdown();
      this.addBackdrop();
    }
  }

  public isLastTier(items: NavigationCategory[]) {
    if (items.find((o) => o.i.length !== 0)) {
      return false;
    }
    return true;
  }

  public closeNavigation(): any {
    this.removeBackdrop();
    this.setTargetElementClasses(
      this.dropdownTarget,
      ["lg:flex", "md:grid", "grid"],
      ["hidden"],
    );
    this.hideDropdown();
    this.clearCategoriesTo(0);
    this.clearPreviousActiveItem(0);
    this.currentPath = [];
  }

  public closeNavigationWithTimeout() {
    trackHover(
      "mouseenter",
      this.navigationTarget,
      this.closeNavigation.bind(this),
    );
  }

  private itemSelected(tier: number, target: HTMLElement) {
    if (tier === 0) {
      this.setTargetElementClasses(
        this.dropdownTarget,
        ["hidden"],
        ["lg:flex", "md:grid", "grid"],
      );
    }
    this.setActive(tier, target.closest("li"));
    this.hideDropdown();
    this.addBackdrop();
  }

  private selectCategory(
    tier: number,
    key: string | null,
  ): NavigationCategory[] {
    if (key == null) {
      this.clearCategoriesTo(0);
      return [];
    }

    this.clearCategoriesTo(tier);
    const emittedController = this.controllerOfTier(tier);
    if (emittedController !== undefined) {
      const items = emittedController.items;
      const category = items.find((el) => el.k === key);
      if (category !== undefined) {
        this.appendTier(category.i, category);
        return category.i;
      }
    }
    return [];
  }

  private clearPreviousActiveItem(tier: number) {
    const tierTarget = this.controllerOfTier(tier);
    if (tierTarget !== null && tierTarget !== undefined) {
      tierTarget.element.querySelectorAll("li").forEach((el) => {
        this.setTargetElementClasses(el, ["active"], []);
      });
    }
  }

  private setActive(tier: number, target: HTMLElement | null): void {
    this.clearPreviousActiveItem(tier);
    if (target) {
      this.setTargetElementClasses(target, [], ["active"]);
    }
  }

  private clearCategoriesTo(tier: number): void {
    this.tierControllers.slice(tier).forEach((c) => {
      if (c.element !== null && c.element.parentNode !== null) {
        c.element.parentNode.removeChild(c.element);
      }
      this.tierControllers.pop();
    });
  }

  private controllerOfTier(tier: number): ControllerWithItems {
    if (tier === 0) {
      return this;
    }

    return this.tierControllers[tier - 1] as ControllerWithItems;
  }

  private parseItems(): NavigationCategory[] {
    const itemsStr = this.data.get("categories");

    if (itemsStr === null) {
      return [];
    }

    try {
      return JSON.parse(itemsStr) as NavigationCategory[];
    } catch (e: any) {
      throw new Error(
        `Could not parse navigation items: for controller ${this.identifier}, was not valid JSON: ${itemsStr}`,
      );
    }
  }

  private parseKeyAttribute(link: HTMLElement): string {
    if (link !== null) {
      return link.getAttribute("key") || "";
    }
    return "";
  }

  private showDropdown() {
    this.setTargetElementClasses(
      this.primaryCategoryListTarget,
      ["hidden"],
      [],
    );
  }

  private hideDropdown() {
    this.setTargetElementClasses(
      this.primaryCategoryListTarget,
      [],
      ["hidden"],
    );
  }

  private removeBackdrop() {
    this.setTargetElementClasses(this.backdropTarget, ["fixed"], ["hidden"]);
  }

  private addBackdrop() {
    this.setTargetElementClasses(this.backdropTarget, ["hidden"], ["fixed"]);
  }
}
