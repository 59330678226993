import FlashController from "decor/flash_controller";
import BaseController from "decor/base_controller";
import { Decor__FlashControllerIdentifier } from "controllers/identifiers";

export default class FlashExamplesController extends BaseController {
  public static outlets = [Decor__FlashControllerIdentifier];

  private declare readonly decorFlashOutlet: FlashController;

  public toggleFlash() {
    this.decorFlashOutlet.text = "Hello from a toggled flash box";
    this.decorFlashOutlet.toggle();
  }

  public revealBriefly() {
    this.decorFlashOutlet.text = "Hello from a revealed flash box";
    this.decorFlashOutlet.reveal(1000);
  }
}
