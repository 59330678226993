import BaseController from "decor/base_controller";
import DataTableController from "decor/tables/data_table_controller";
import { Decor__Tables__DataTableControllerIdentifier } from "controllers/identifiers";

export default class RouteFormController extends BaseController {
  public static targets = ["templateRow"];
  private declare readonly templateRowTarget: HTMLDivElement;

  public static outlets = [Decor__Tables__DataTableControllerIdentifier];
  private declare readonly decorTablesDataTableOutlet: DataTableController;
  private get tableController() {
    return this.decorTablesDataTableOutlet;
  }
  addDay(event: Event) {
    event.preventDefault();

    // Get the hidden row (the template)
    const template = this.templateRowTarget as HTMLElement;

    // Clone the template content
    const clone = document.importNode(template, true);

    // Remove the hidden class from the clone
    clone.classList.remove("hidden");

    // Append the clone to the tbody
    this.tableController.appendRow(clone);
  }

  removeDay(event: Event) {
    event.preventDefault();

    // Get the clicked element
    const target = event.target as HTMLElement;

    // Find the closest row to the clicked element
    const row = target.closest("tr");

    // If a row was found, remove it
    if (row) {
      row.remove();
    }
  }
}
