import {
  FormControlElement,
  FormControlErrors,
  FormControlValidationResponse,
} from "lib/types";

import BaseController from "decor/base_controller";
import {
  missingCheckboxValue,
  missingRadioValue,
  missingValue,
  outOfRange,
  patternMismatch,
  typeMismatch,
  wrongLength,
} from "lib/util/form_validators";

const NO_ERRORS: FormControlErrors = {
  missingValue: false,
  outOfRange: false,
  patternMismatch: false,
  typeMismatch: false,
  wrongLength: false,
};

// Form controls such as 'input' and 'select' have this controller, though
// its use is usually constraint to be within a form-field component, such as
// 'switch'.
export default class FormControlController extends BaseController {
  // TODO state management in stimulus should be in the DOM
  private listening = false;
  private touched = false;

  public onInitialize() {
    this.onDisconnect(() => {
      this.autoValidation = false;
    });
    return super.onInitialize();
  }

  public set autoValidation(shouldAutoValidate: boolean) {
    // By default, a form control will not validate itself on input/blur.
    // This is an opt-in arrangement by calling this setter.
    // Typically this is done by a parent form controller so that
    // form controls are only validated when they are part of a form.
    if (shouldAutoValidate) {
      this.setupEventListeners();
    } else {
      this.teardownEventListeners();
    }
  }

  public validate() {
    const errors = this.validationErrors;
    const valid = Object.values(errors).every((e) => e === false);

    if (!valid) {
      this.formControlElement.setCustomValidity("invalid");
    }

    this.touched = true;
    this.emitValidationEvent({ errors, valid });

    return { errors, valid };
  }

  public focusControl() {
    const el = this.formControlElement;
    el.focus();
  }

  public get formControlElement() {
    return this.element as FormControlElement;
  }

  public get required() {
    return this.element.hasAttribute("required");
  }

  public set required(required: boolean) {
    if (required) {
      this.element.setAttribute("required", "required");
    } else {
      this.element.removeAttribute("required");
    }
  }

  private get validationErrors(): FormControlErrors {
    if (this.disabled) {
      // If the form field is disabled, it won't be sent to the server.
      // So, let's not validate it.
      return { ...NO_ERRORS };
    }

    // Validations TODO: (http://parsleyjs.org/doc/index.html#validators)
    //  - check / mincheck / maxcheck (e.g. at least 2 checkboxes)
    //  - equalto (another control, e.g. confirm password)
    return {
      missingValue: this.missingValueError,
      outOfRange: this.outOfRangeError,
      patternMismatch: this.patternMismatchError,
      typeMismatch: this.typeMismatchError,
      wrongLength: this.wrongLengthError,
    };
  }

  private emitValidationEvent({
    errors,
    valid,
  }: FormControlValidationResponse) {
    this.dispatch("validated", {
      bubbles: true,
      cancelable: false,
      detail: {
        errors,
        valid,
      },
    });
  }

  private setupEventListeners() {
    if (this.listening) {
      // Don't want to set these up again!
      return;
    }

    this.element.addEventListener("input", this.handleInputEvent);
    this.element.addEventListener("focus", this.handleFocusEvent);
    this.element.addEventListener("blur", this.handleBlurEvent);
    this.listening = true;
  }

  private teardownEventListeners() {
    if (!this.listening) {
      // Nothing to do.
      return;
    }

    this.element.removeEventListener("input", this.handleInputEvent);
    this.element.removeEventListener("blur", this.handleBlurEvent);
    this.listening = false;
  }

  private handleInputEvent = () => {
    if (this.touched) {
      this.validate();
    }
  };

  private handleFocusEvent = () => {
    if (this.formControlElement.value) {
      this.touched = true;
    }
  };

  private handleBlurEvent = () => {
    this.validate();
  };

  private get missingValueError() {
    const el = this.formControlElement;
    switch (el.type) {
      case "radio": {
        const inputGroup = Array.from(document.getElementsByTagName("input"));
        const radioGroup = inputGroup.filter(
          (input) => input.getAttribute("name") === el.getAttribute("name"),
        );
        const radioAttributes = radioGroup.map(this.mapCheckableAttributes);
        return missingRadioValue(radioAttributes);
      }
      case "checkbox": {
        const checkboxAttributes = this.mapCheckableAttributes(
          el as HTMLInputElement,
        );
        return missingCheckboxValue(checkboxAttributes);
      }
      default:
        return missingValue({
          required: el.hasAttribute("required"),
          value: el.value,
        });
    }
  }

  private get outOfRangeError() {
    const el = this.formControlElement;
    const attributes = {
      gt: this.data.get("validate-gt"),
      lt: this.data.get("validate-lt"),
      max: el.getAttribute("max"),
      min: el.getAttribute("min"),
      value: el.value,
    };
    return outOfRange(attributes);
  }

  private get patternMismatchError() {
    const el = this.formControlElement;
    const attributes = {
      pattern: el.getAttribute("pattern"),
      type: el.type,
      value: el.value,
    };
    return patternMismatch(attributes);
  }

  private get wrongLengthError() {
    const el = this.formControlElement;
    const attributes = {
      max: el.getAttribute("maxlength"),
      min: el.getAttribute("minlength"),
      value: el.value,
    };
    return wrongLength(attributes);
  }

  private get typeMismatchError() {
    const el = this.formControlElement;
    const attributes = {
      type: this.data.get("validate-type"),
      value: el.value,
    };
    return typeMismatch(attributes);
  }

  private mapCheckableAttributes(field: HTMLInputElement) {
    return {
      checked: field.checked,
      required: field.hasAttribute("required"),
    };
  }
}
