import axios from "lib/axios";
import BaseController from "decor/base_controller";
import { localeMessage } from "lib/i18n";
import { markAsSafeHTML, safelySetInnerHTML } from "lib/util/safe_html";
import { SafeHTMLContent } from "lib/types";

export interface NotificationShowOptions {
  content?: SafeHTMLContent;
  timeout?: number;
  contentHref?: string;
}
export interface NotificationDismissOptions {
  id: string;
}
export type NotificationShowEvent = CustomEvent<NotificationShowOptions>;

export type NotificationDismissEvent = CustomEvent<NotificationDismissOptions>;

type NotificationElement = HTMLDivElement;

export const NOTIFICATION_MANAGER_CLASS_NAME = "decor--notification-manager";

const NOTIFICATION_CLASSNAME = `${NOTIFICATION_MANAGER_CLASS_NAME}-notification`;

const DEFAULT_DISMISS_AFTER_MS = 3000;
const DISMISS_ALL_STAGGER_MS = 50;

export default class NotificationManagerController extends BaseController {
  public static targets = ["notificationContainer"];

  private declare readonly notificationContainerTarget: HTMLDivElement;

  public static classes = [
    "notificationBase",
    "entering",
    "enteringFrom",
    "enteringTo",
    "leaving",
    "leavingFrom",
    "leavingTo",
  ];

  protected declare readonly notificationBaseClasses: string[];
  protected declare readonly enteringClasses: string[];
  protected declare readonly enteringFromClasses: string[];
  protected declare readonly enteringToClasses: string[];
  protected declare readonly leavingClasses: string[];
  protected declare readonly leavingFromClasses: string[];
  protected declare readonly leavingToClasses: string[];

  private currentNotificationId = 0;

  public onInitialize() {
    this.onConnect(() => {
      const notifications = this.getOptionalDataAttrAsJSON<
        NotificationShowOptions[]
      >("initialNotifications");
      if (notifications) {
        notifications.forEach((notificationOptions) => {
          this.showNotification(notificationOptions);
        });
      }
    });

    return super.onInitialize();
  }

  public async handleShowEvent(evt: NotificationShowEvent) {
    this.showNotification(evt.detail);
  }

  public async showNotification(options: NotificationShowOptions) {
    const { content, timeout, contentHref } = options;
    const notification = await this.createNotification(content, contentHref);
    const showTimeout = timeout || DEFAULT_DISMISS_AFTER_MS;
    if (showTimeout !== Infinity) {
      const timerId = window.setTimeout(
        () => this.dismissNotification(notification.id),
        showTimeout,
      );
      notification.dataset.dismissTimerId = timerId.toString();
    }
    // .prepend() not available in IE11
    // this.element.prepend(notification);
    this.notificationContainerTarget.insertBefore(
      notification,
      this.notificationContainerTarget.firstChild,
    );

    notification.addEventListener("click", () => {
      this.dismissNotification(notification.id);
    });

    notification.addEventListener("touchend", () => {
      this.dismissNotification(notification.id);
    });
  }

  public handleDismissAllEvent() {
    // This isn't exactly the 'stimulus way' - we should probably be using targets instead.
    // But, it works.
    Array.from(
      this.notificationContainerTarget.getElementsByClassName(
        NOTIFICATION_CLASSNAME,
      ),
    )
      .reverse() // Bottom notification should disappear first
      .forEach((node, idx) => {
        const el = node as NotificationElement;
        clearTimeout(+el.dataset.dismissTimerId!);
        setTimeout(
          () => this.dismissNotification(el.id),
          idx * DISMISS_ALL_STAGGER_MS,
        );
      });
  }

  public handleDismissSingleEvent(evt: NotificationDismissEvent) {
    const {
      detail: { id },
    } = evt;
    this.dismissNotification(id);
  }

  private nextNotificationId() {
    const next = this.currentNotificationId + 1;
    this.currentNotificationId = next;
    return `${NOTIFICATION_CLASSNAME}-${next}`;
  }

  private async createNotification(
    content: SafeHTMLContent | undefined,
    contentHref: string | undefined,
  ): Promise<NotificationElement> {
    const notification = document.createElement("div");
    notification.id = this.nextNotificationId();
    this.setTargetElementClasses(
      notification,
      [],
      [NOTIFICATION_CLASSNAME].concat(this.notificationBaseClasses),
    );
    this.toggleTargetElementTransitionClasses(
      notification,
      true,
      this.enteringClasses,
      this.enteringFromClasses,
      this.enteringToClasses,
      this.leavingClasses,
      this.leavingFromClasses,
      this.leavingToClasses,
    );

    if (contentHref) {
      // TODO: What if contentHref already has a query string parameters?
      const remoteContent = await this.getRemoteContent(
        `${contentHref}?notification_id=${notification.id}`,
      );
      safelySetInnerHTML(notification, remoteContent);
    } else if (content) {
      safelySetInnerHTML(notification, content);
    }

    return notification;
  }

  private getRemoteContent(contentHref: string): Promise<SafeHTMLContent> {
    return this.getContent(contentHref).catch((err) => {
      console.warn(err);
      const errorMessage = localeMessage("generic_server_error");
      return markAsSafeHTML(errorMessage);
    });
  }

  private getContent(url: string): Promise<SafeHTMLContent> {
    return axios
      .get<string>(url, {
        headers: {
          "Content-Type": "text/html",
        },
      })
      .then((response) => markAsSafeHTML(response.data));
  }

  private dismissNotification(notificationId: string) {
    // Only try to dismiss the notification if it is still in the DOM,
    // and it is not being dismissed
    const notification = document.getElementById(notificationId);
    if (notification) {
      this.toggleTargetElementTransitionClasses(
        notification,
        false,
        this.enteringClasses,
        this.enteringFromClasses,
        this.enteringToClasses,
        this.leavingClasses,
        this.leavingFromClasses,
        this.leavingToClasses,
      );
      setTimeout(() => {
        this.removeNotification(notification);
      }, 150);
    }
  }

  private removeNotification(target: HTMLElement) {
    // .remove() not available in IE11
    // target.remove();
    if (this.notificationContainerTarget.contains(target)) {
      this.notificationContainerTarget.removeChild(target);
    }
  }
}
