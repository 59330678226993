const DEFAULT_TIMEOUT = 300;

export default function trackHover(
  eventType: string,
  target: HTMLBaseElement | HTMLElement,
  callback: () => void,
  timeout = DEFAULT_TIMEOUT,
) {
  const timer = setTimeout(callback, timeout);

  target.addEventListener(eventType, () => {
    clearTimeout(timer);
  });
}
