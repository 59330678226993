import BaseController from "decor/base_controller";

export default class ButtonController extends BaseController {
  public set disabled(disabled: boolean) {
    if (disabled) {
      this.element.setAttribute("disabled", "disabled");
    } else {
      this.element.removeAttribute("disabled");
    }
  }
}
