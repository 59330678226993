import BaseController from "decor/base_controller";
import { markAsSafeHTML, safelySetInnerHTML } from "lib/util/safe_html";
import axios from "lib/axios";

export default class ShoppingCartDropdownController extends BaseController {
  public static readonly targets = ["total"];

  private declare readonly totalTarget: HTMLElement;

  public async updateTotalOnDisplay(event: CustomEvent) {
    const location = event.detail.basePath.split("/").slice(3, 7).join("/");

    // Trigger update of total price
    axios
      .get<string>(`/${location}/carts/total`, {
        headers: { Accept: "text/fragment" },
      })
      .then((resp) => {
        safelySetInnerHTML(this.totalTarget, markAsSafeHTML(resp.data));
      })
      .catch((e) => {
        console.error("Could not fetch cart total fragment", e);
      });
  }
}
