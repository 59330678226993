import BaseController from "decor/base_controller";

export default class UnreadItemController extends BaseController {
  public static targets = ["ago"];

  // private declare readonly agoTarget: HTMLElement;

  public get sentAt(): number {
    return Number(this.data.get("sentAt"));
  }

  public get threadUid(): string {
    return this.data.get("threadUid")!;
  }
}
