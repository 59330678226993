import Rails from "./lib/rails";
import "./lib/third_party_libs";

// TODO: this should be set according to the current locale
setAppConfiguration("locale", "en");

console.debug("[Boot] Register Confinus JS Components");
// Prepare client side controllers
import { application } from "./controllers";
import { setAppConfiguration } from "lib/app_configuration";

// @ts-ignore
application.debug = process.env.NODE_ENV === "development";

console.debug("[Boot] Start UJS");
// This breaks JS as it raises in 7.1.1. The fix is in main:
// https://github.com/rails/rails/commit/f7617808992c9c2a77920ccf4f2a4a42160fe1bd
Rails.start();
