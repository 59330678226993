import BaseController from "decor/base_controller";

// Ispired by tailwindcss-stimulus-components Popover
export default class TooltipController extends BaseController {
  public static targets = ["content"];

  private declare readonly contentTarget: HTMLElement;

  private debounceTimer?: any = undefined;

  initialize() {
    this.contentTarget.setAttribute(
      "style",
      `transform:translate(${this.data.get("translateX")}, ${this.data.get(
        "translateY",
      )});`,
    );
  }

  public mouseOver() {
    this.debounceSimulatedEvents(() => {
      this.show();
    });
  }

  public mouseOut() {
    this.debounceSimulatedEvents(() => {
      this.hide();
    });
  }

  public handleClick(event: Event) {
    this.debounceSimulatedEvents(() => {
      this.toggle();
    });
  }

  private debounceSimulatedEvents(callback: () => void) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      this.debounceTimer = undefined;
      callback();
    }, 100);
  }

  // Toggle the popover on demand
  public toggle() {
    if (this.contentTarget.classList.contains("hidden")) {
      this.show();
    } else {
      this.hide();
    }
  }

  public show() {
    this.setTargetElementClasses(this.contentTarget, ["hidden"], []);
  }

  public hide() {
    this.setTargetElementClasses(this.contentTarget, [], ["hidden"]);
  }
}
