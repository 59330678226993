import BaseController from "decor/base_controller";
import { NavigationCategory } from "lib/types";
import ProductNavigationController from "./product_navigation_controller";

export default class ProductNavigationTierController extends BaseController {
  public items: NavigationCategory[] = [];
  private tier = 1;
  private parentController!: ProductNavigationController;
  private parentCategory!: NavigationCategory;

  public onInitialize() {
    this.onConnect(() => {
      this.parentController = this.parseParentController();
      this.tier = this.parseTier();
      this.items = this.parseItems();
      this.parentCategory = this.parseParentCategory();

      this.render();

      return () => {
        this.element?.parentNode?.removeChild(this.element);
      };
    });

    return super.onInitialize();
  }

  public itemHovered(e: Event) {
    this.parentController.tierSelected(e, this.tier);
  }

  public get isMobile() {
    return window.innerWidth < 840;
  }

  private render(): void {
    let itemsToRender = this.items.filter((i) => i.s);

    if (this.isMobile) {
      const seeAllHtml = `
        <li class="lg:hidden bg-gray-100 text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
          <a class="font-normal truncate flex items-center" data-action="click->mercor--product-navigation#backMenu">
            <svg fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" id="decor--icon-2205020-33" class="decor--icon lg:hidden h-5 w-5 text-gray-400" role="img"><path stroke-linecap="round" stroke-linejoin="round" d="m11 17-5-5m0 0 5-5m-5 5h12"></path></svg>
            <span class="font-light pl-2">Go Back...</span>
          </a>
        </li>
        <li class="list-item text-base font-light border-l border-l-white hover:border-gray-200">
          <a class="block" href=${this.parentCategory.u}>
            <div class="lg:hidden flex items-center px-4 py-1 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="flex-shrink-0">
                  ${
                    this.parentCategory.m
                      ? `<img class="h-12 w-12 rounded-sm bg-gray-200" src="${this.parentCategory.m}" alt="${this.parentCategory.n}">`
                      : '<div class="h-12 w-12 rounded-sm bg-gray-200"></div>'
                  }
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div><p class="text-sm font-light text-gray-700 hover:text-gray-900 truncate">
              See all ${this.parentCategory.n}</p></div>
                </div>
              </div>
              <div><svg fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="decor--icon lg:group-hover:text-primary-800 h-5 w-5 text-gray-400" role="img"><path stroke-linecap="round" stroke-linejoin="round" d="m9 5 7 7-7 7"></path></svg></div>
            </div>
          </a>
        </li>
      `;
      this.element.insertAdjacentHTML("beforeend", seeAllHtml);
      this.renderItems(itemsToRender);
      return;
    }
    // Truncate to first 9 items if there are parent categories present & add placeholder
    if (
      itemsToRender.length > 9 &&
      itemsToRender.find((el) => el.sic > 0) !== undefined
    ) {
      itemsToRender = itemsToRender.slice(0, 9);
      this.renderItems(itemsToRender);

      const seeAllHtml = `
        <li class="text-base lg:text-sm font-light italic text-primary-800">
          <a href=${this.parentCategory.u}>See all</a>
        </li>
      `;

      this.element.insertAdjacentHTML("beforeend", seeAllHtml);
      return;
    }

    this.renderItems(itemsToRender.slice(0, 9));

    if (itemsToRender.length > 10) {
      this.parentController.appendTier(
        this.items.slice(9),
        this.parentCategory,
      );
    }
  }

  private renderItems(items: NavigationCategory[]) {
    items.forEach((item) => {
      let arrowHtml = "";
      let actionHtml = "";

      if (item.sic > 0) {
        arrowHtml =
          '<svg fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="decor--icon lg:group-hover:text-primary-800 h-5 w-5 text-gray-400" role="img"><path stroke-linecap="round" stroke-linejoin="round" d="m9 5 7 7-7 7"></path></svg>';
        actionHtml = `mouseover->mercor--product-navigation-tier#itemHovered
          touchend->mercor--product-navigation-tier#itemHovered
          ${this.tier}Selected click->mercor--product-navigation-tier#itemHovered`;
      }

      const imageHtml = `<img class="h-12 w-12 rounded-sm bg-gray-200" src="${item.m}" alt="${item.n}">`;

      const itemHtml = `<li class="list-item lg:table lg:w-full lg:m-0 lg:px-1 lg:py-1 lg:h-6 text-base lg:text-sm font-light border-l border-l-white hover:border-gray-200">
                <a class="block group lg:table-cell lg:align-middle lg:py-1 lg:pl-2 text-base lg:text-sm lg:rounded-sm"
                   data-action="${actionHtml}" key="${item.k}" href="${
                     item.u
                   }" ${item.e ? 'target="_blank"' : ""}>
                  <div class="lg:hidden flex items-center px-4 py-1 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">
                      <div class="flex-shrink-0">
                        ${
                          item.m
                            ? imageHtml
                            : '<div class="h-12 w-12 rounded-sm bg-gray-200"></div>'
                        }
                      </div>
                      <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div><p class="text-sm font-medium text-gray-700 hover:text-gray-900 truncate">${
                          item.n
                        }</p></div>
                      </div>
                    </div>
                    <div>${arrowHtml}</div>
                  </div>
                  <div class="hidden lg:flex items-center justify-between text-gray-700 hover:text-gray-900 text-sm font-medium">
                    <span class="flex-1">${item.n}</span>
                    <span>${arrowHtml}</span>
                  </div>
                </a>
              </li>`;

      this.element.insertAdjacentHTML("beforeend", itemHtml);
    });
  }

  private parseParentCategory(): NavigationCategory {
    const categoryStr = this.data.get("parentCategory");

    if (categoryStr !== null) {
      try {
        return JSON.parse(categoryStr) as NavigationCategory;
      } catch (e: any) {
        throw new Error(
          `Could not parse category navigation items on tier: for controller ${this.identifier}, was not valid JSON: ${categoryStr}`,
        );
      }
    }
    return {} as NavigationCategory;
  }

  private parseParentController(): ProductNavigationController {
    return this.application.controllers.find((controller) => {
      return controller.context.identifier === "mercor--product-navigation";
    }) as ProductNavigationController;
  }

  private parseItems(): NavigationCategory[] {
    const itemsStr = this.data.get("items");

    if (itemsStr !== null) {
      try {
        return JSON.parse(itemsStr) as NavigationCategory[];
      } catch (e: any) {
        throw new Error(
          `Could not parse navigation items: for controller ${this.identifier}, was not valid JSON: ${itemsStr}`,
        );
      }
    }
    return [] as NavigationCategory[];
  }

  private parseTier(): number {
    return parseInt(this.data.get("tier") as string, 10);
  }
}
