import qs from "query-string";

interface Params {
  [key: string]: string;
}

export function addParametersToQueryString(str: string, params: Params) {
  return qs.stringify({
    ...qs.parse(str),
    ...params,
  });
}

export function removeParameterFromQueryString(str: string, key: string) {
  const search = qs.parse(str) as Params;
  delete search[key];
  return qs.stringify(search);
}

export function removeHashesFromQueryString(
  str: string,
  ...hashKeys: string[]
) {
  const search = qs.parse(str) as Params;
  for (const hashKey of hashKeys) {
    for (const key in search) {
      if (key.split("[")[0] === hashKey) {
        delete search[key];
      }
    }
  }

  return qs.stringify(search);
}
