import BaseController from "decor/base_controller";

export default class SideNavbarSubItemController extends BaseController {
  public static targets = ["title"];

  private declare readonly titleTarget: HTMLHeadingElement;

  public static classes = ["shown", "filtered"];
  protected declare readonly filteredClasses: string[];
  protected declare readonly shownClasses: string[];

  public filterOnSearch(keyword: string): boolean {
    return this.toggleOnSearch(
      keyword,
      this.element,
      [this.titleTarget],
      this.shownClasses,
      this.filteredClasses,
    );
  }
}
