import Big from "big.js";

interface TotalPriceOpts {
  extraChargeMultiplier: string | number;
  priceMultiplier: string | number;
  quantity: string;
  quantityType: string;
  size?: string;
  sizeExcludedFromCalculation?: boolean;
}

interface TotalPriceForDisplayOpts extends TotalPriceOpts {
  currency: string;
  locale: string;
}

interface ExtraInfoAmountForDisplayOpts {
  locale: string;
  multiplier: string | number;
  quantity: string;
  quantityType: string;
  size?: string;
  sizeCalculationPerformDivision?: boolean;
  sizeExcludedFromCalculation?: boolean;
  sizeUnitConversionFactor?: string | number;
}

const ZERO = Number(0);
export const BIG_ZERO = new Big(0);

export function isNullOrBigZero(input: Big | null | undefined) {
  return input === null || input === undefined || isBigZero(input);
}

export function parseVariantSize(
  sizeRaw?: string,
  sizeExcludedFromCalculation?: boolean,
): Big | undefined {
  if (sizeExcludedFromCalculation) {
    return new Big(1);
  }
  // Intentionally double equals to check for undefined and null
  if (sizeRaw === null || sizeRaw === undefined || sizeRaw.trim() === "") {
    return;
  }
  return newPositiveBigOrNull(sizeRaw) || new Big(1);
}

export function calculateTotalPrice({
  extraChargeMultiplier,
  priceMultiplier,
  quantity,
  quantityType,
  size,
  sizeExcludedFromCalculation,
}: TotalPriceOpts): Big | null {
  const quantityBig = parseQuantity(quantity, quantityType);

  if (quantityBig === null) {
    return null;
  }

  const sizeBig = parseVariantSize(size, sizeExcludedFromCalculation);
  if (sizeBig === null || sizeBig === undefined) {
    return null;
  }

  const priceMultiplierBig = parseQuantityPriceMultiplier(priceMultiplier);
  const extraChargeMultiplierBig = parseQuantityPriceMultiplier(
    extraChargeMultiplier,
  );

  // Use big.js to perform arithmetic, to prevent floating point rounding errors
  const totalMultiplierBig = priceMultiplierBig.add(extraChargeMultiplierBig);
  return totalMultiplierBig.times(quantityBig).times(sizeBig).div(100);
}

export function calculateTotalPriceNumber(
  opts: TotalPriceForDisplayOpts,
): number {
  const { currency, locale, ...priceFactors } = opts;

  const totalPriceBig = calculateTotalPrice(priceFactors);
  const totalPriceNumber =
    totalPriceBig !== null ? Number.parseFloat(totalPriceBig.toFixed(2)) : 0;

  return totalPriceNumber;
}

export function calculateTotalPriceForDisplay(
  opts: TotalPriceForDisplayOpts,
  totalPriceNumber: number,
): string {
  const { currency, locale } = opts;
  const totalPriceForDisplay = totalPriceNumber.toLocaleString(locale, {
    currency,
    style: "currency",
  });
  return totalPriceForDisplay;
}

export function calculateExtraInfoAmountForDisplay({
  locale,
  multiplier,
  quantity,
  quantityType,
  size,
  sizeCalculationPerformDivision,
  sizeExcludedFromCalculation,
}: ExtraInfoAmountForDisplayOpts) {
  const quantityBig = parseQuantity(quantity, quantityType);
  if (isNullOrBigZero(quantityBig)) {
    return ZERO.toLocaleString(locale);
  }

  const sizeBig = parseVariantSize(size, sizeExcludedFromCalculation);
  if (sizeBig === null || sizeBig === undefined) {
    return "-";
  }
  if (isBigZero(sizeBig)) {
    return ZERO.toLocaleString(locale);
  }

  const multiplierBig = parseQuantityPriceMultiplier(multiplier);
  if (isNullOrBigZero(multiplierBig)) {
    return ZERO.toLocaleString(locale);
  }

  // Use big.js to perform arithmetic, to prevent floating point rounding errors
  let extraInfoAmountBig = multiplierBig.times(quantityBig!);
  extraInfoAmountBig = sizeCalculationPerformDivision
    ? extraInfoAmountBig.div(sizeBig)
    : extraInfoAmountBig.times(sizeBig);

  // Format for display
  const extraInfoAmountNumber = Number.parseFloat(
    extraInfoAmountBig.toFixed(2),
  );
  return extraInfoAmountNumber.toLocaleString(locale);
}

// Helper methods

export function newPositiveBigOrNull(
  input: string | number,
  minValue: number = 0,
): Big | null {
  if (typeof input === "string" && input.length === 0) {
    return null;
  }
  try {
    const big = new Big(input);
    return big.gte(minValue) ? big : null;
  } catch (error) {
    return null;
  }
}

function isBigZero(input: Big) {
  return BIG_ZERO.eq(input);
}

function parseQuantityPriceMultiplier(multiplierRaw: string | number): Big {
  const multiplierBig = newPositiveBigOrNull(multiplierRaw);
  if (multiplierBig === null) {
    // This is an error that the user cannot recover from.
    throw new Error(
      `multiplier was not a positive number: ${multiplierRaw || '""'}`,
    );
  }

  return multiplierBig;
}

function parseQuantity(quantityRaw: string, quantityType: string): Big | null {
  switch (quantityType) {
    case "float": {
      return newPositiveBigOrNull(quantityRaw);
    }

    case "integer": {
      if (quantityRaw.includes(".")) {
        // Can't parse a decimal number as an integer.
        return null;
      }
      return newPositiveBigOrNull(quantityRaw);
    }

    default:
      // quantityType not recognised
      throw new Error(
        `quantityType '${
          quantityType || '""'
        }' is invalid, should be 'float' or 'integer'`,
      );
  }
}
