import PurchaseConfiguratorVariantAbstract from "mercor/purchase_configurator/purchase_configurator_variant_abstract";
import { calculateExtraInfoAmountForDisplay } from "lib/price_calculations";
import { getAppConfiguration } from "lib/app_configuration";

interface IDataAttributes {
  multiplier: string | number;
  sizeExcludedFromCalculation?: boolean;
  sizeCalculationPerformDivision?: boolean;
}

interface IUpdateValueOptions {
  quantity: string;
  quantityType: string;
  size?: string;
  fallbackDisplay: string;
}

abstract class BasePurchaseConfiguratorExtraInfoController extends PurchaseConfiguratorVariantAbstract {
  public readonly valueTarget!: HTMLSpanElement;
}

export default class PurchaseConfiguratorExtraInfoController extends (PurchaseConfiguratorVariantAbstract as typeof BasePurchaseConfiguratorExtraInfoController) {
  public static targets = ["value"];

  public updateValue({
    quantity,
    quantityType,
    size,
    fallbackDisplay,
  }: IUpdateValueOptions) {
    let valueDisplay = null;

    try {
      const {
        multiplier,
        sizeExcludedFromCalculation,
        sizeCalculationPerformDivision,
      } = this.getRequiredDataAttrAsJSON<IDataAttributes>("data");
      valueDisplay = calculateExtraInfoAmountForDisplay({
        locale: getAppConfiguration("locale"),
        multiplier,
        quantity,
        quantityType,
        size,
        sizeCalculationPerformDivision,
        sizeExcludedFromCalculation,
      });
    } catch (e) {
      // Ru-roh, something's gone wrong when parsing the attributes
      // TODO handle this error: show the user somehow?
      console.error("Extra info calc error", e);
    }

    // Set the value, or the fallback
    this.value = valueDisplay !== null ? valueDisplay : fallbackDisplay;
  }

  private set value(val: string) {
    this.valueTarget.textContent = val;
  }
}
