import SelectController from "decor/forms/select_controller";
import BaseController from "decor/base_controller";
import { markAsSafeHTML } from "lib/util/safe_html";
import { Category } from "lib/types";
import { Decor__Forms__SelectControllerIdentifier } from "controllers/identifiers";

export default class ProductCategoryFilterTierController extends BaseController {
  public static outlets = [Decor__Forms__SelectControllerIdentifier];
  private declare readonly decorFormsSelectOutlet: SelectController;
  private get selectController() {
    return this.decorFormsSelectOutlet;
  }

  public items!: Category[];

  public onInitialize() {
    this.items = this.parseItems();
    return super.onInitialize();
  }

  public set disabled(disabled: boolean) {
    if (disabled) {
      this.selectController.empty("Category");
    }

    this.selectController.disabled = disabled;
  }

  public set options(newOptions: Category[]) {
    this.data.set("categories", JSON.stringify(newOptions));
    this.items = newOptions;

    if (newOptions.length === 0) {
      this.disabled = true;
      return;
    }

    this.selectController.empty("Category");
    const newHtml = newOptions
      .map((category) => `<option value='${category.i}'>${category.n}</option>`)
      .reduce((previous, current) => (previous += current));

    this.selectController.options = markAsSafeHTML(
      this.selectController.optionsHTML + newHtml,
    );
    this.disabled = false;
  }

  public get value(): string {
    return this.selectController.value;
  }

  private parseItems(): Category[] {
    const itemsStr = this.data.get("categories");
    if (itemsStr !== null) {
      try {
        return JSON.parse(itemsStr) as Category[];
      } catch (e: any) {
        throw new Error(
          `Could not parse category filter items: for controller ${this.identifier}, was not valid JSON: ${itemsStr}`,
        );
      }
    }

    return [] as Category[];
  }
}
