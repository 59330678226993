import BaseController from "decor/base_controller";

export default class SubmitPriceUpdateController extends BaseController {
  public static targets = ["requiresScheduled"];

  protected declare readonly requiresScheduledTarget: HTMLInputElement;

  public publishScheduled(evt: Event) {
    // Set form attribute to indicate we are scheduling
    this.requiresScheduledTarget.setAttribute("value", "true");
  }
}
