import BaseController from "decor/base_controller";

export default class InputFinalDetailsConfirmationController extends BaseController {
  public static targets = ["checkbox", "submit"];

  private declare readonly submitTarget: HTMLButtonElement;
  private declare readonly checkboxTarget: HTMLInputElement;

  public handleConfirmationChanges() {
    this.submitTarget.disabled = !this.checkboxTarget.checked;
  }
}
