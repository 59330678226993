import Rails from "@rails/ujs";
// import "@hotwired/turbo-rails"; // Enable in future to add Turbo support
import Trix from "trix";
import * as ActiveStorage from "@rails/activestorage";
import "@rails/actiontext";
import { showConfirmModal } from "./overlay/modal";
import { ModalLifecycleEvent } from "decor/modal_controller";

const alwaysTrue = () => true;

const customConfirm = (message: string, element: HTMLElement) => {
  // Listen for the dialog closing event.
  // If the closeReason corresponds to the 'confirm' action, then allow the confirm through.
  function handleDialogClosing({
    detail: { closeReason },
  }: ModalLifecycleEvent) {
    if (closeReason === "confirm") {
      // Song and dance to re-submit the original action.
      Rails.confirm = alwaysTrue;
      element.click();
      Rails.confirm = customConfirm;
    }
  }

  // Reveal the custom dialog.
  // TODO integrate with i18n module
  const confirmLabel = element.getAttribute("data-confirm-yes") || "OK";
  const cancelLabel = element.getAttribute("data-confirm-no") || "Cancel";
  showConfirmModal({
    defaultReason: "cancel",
    negativeButtonReason: "cancel",
    negativeButtonLabel: cancelLabel,
    positiveButtonReason: "confirm",
    positiveButtonLabel: confirmLabel,
    message: message,
    onClosing: handleDialogClosing,
  });

  // Interaction with dialog happens asynchronously,
  // so we need to initially return false to prevent submission.
  return false;
};

Rails.confirm = customConfirm;

(window as any).Trix = Trix;

console.debug("[Boot] Prepare Rails client side libraries");
// Start Rails client side components
ActiveStorage.start();

export default Rails;
